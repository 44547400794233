"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnalytics = exports.AnalyticsProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var uuid_1 = require("uuid");
var graphql_api_patient_1 = require("@fdha/graphql-api-patient");
var analytics_next_1 = require("@segment/analytics-next");
var plugins_1 = require("./plugins");
var Context = (0, react_1.createContext)({ isLoading: false });
var AnalyticsProvider = function (_a) {
    var children = _a.children, hubs = _a.hubs;
    var IS_DEV = process.env.NODE_ENV !== 'production';
    var sessionIdRef = (0, react_1.useRef)((0, uuid_1.v4)().toString());
    var sessionId = sessionIdRef.current;
    var _b = (0, graphql_api_patient_1.useGetInfoForAnalyticsQuery)(), data = _b.data, isLoading = _b.loading;
    var commonProps = (0, react_1.useMemo)(function () {
        return {
            sessionId: sessionId,
            environment: data === null || data === void 0 ? void 0 : data.environment,
            title: data === null || data === void 0 ? void 0 : data.user.userType,
        };
    }, [sessionId, data === null || data === void 0 ? void 0 : data.environment, data === null || data === void 0 ? void 0 : data.user.userType]);
    var analyticsClient = (0, react_1.useMemo)(function () {
        if (!IS_DEV && !isLoading) {
            if ((data === null || data === void 0 ? void 0 : data.keys.analyticsKey) && (data === null || data === void 0 ? void 0 : data.environment)) {
                return analytics_next_1.AnalyticsBrowser.load({ writeKey: data.keys.analyticsKey }, {
                    integrations: {
                        'Segment.io': {
                            deliveryStrategy: {
                                strategy: 'batching',
                                config: {
                                    size: 10,
                                    timeout: 60000,
                                },
                            },
                        },
                    },
                });
            }
            else {
                console.error('Unable to initialize, analytics key or environment is missing');
            }
        }
    }, [IS_DEV, isLoading, data === null || data === void 0 ? void 0 : data.keys.analyticsKey, data === null || data === void 0 ? void 0 : data.environment]);
    analyticsClient === null || analyticsClient === void 0 ? void 0 : analyticsClient.ready(function () {
        analyticsClient === null || analyticsClient === void 0 ? void 0 : analyticsClient.register((0, plugins_1.trackEnrichment)(commonProps, data === null || data === void 0 ? void 0 : data.user.userId, hubs), (0, plugins_1.pageEnrichment)(commonProps, data === null || data === void 0 ? void 0 : data.user.userId, hubs));
    });
    return ((0, jsx_runtime_1.jsx)(Context.Provider, __assign({ value: { analyticsClient: analyticsClient, isLoading: isLoading } }, { children: children })));
};
exports.AnalyticsProvider = AnalyticsProvider;
var useAnalytics = function () { return (0, react_1.useContext)(Context); };
exports.useAnalytics = useAnalytics;
