import React from 'react';

import LoginBase from '../components/LoginBase';
import { Login } from '../components';

export const LoginPage = () => {
  return (
    <LoginBase>
      <Login />
    </LoginBase>
  );
};
