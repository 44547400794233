export enum ScheduleCompleteWithin {
  OneDay = 1,
  TwoDays = 2,
  ThreeDays = 3,
  OneWeek = 7,
  EndOfDay = 99,
}

export enum SurveyFrequency {
  Biweekly = 'Biweekly',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Once = 'Once',
  Triweekly = 'Triweekly',
  Weekly = 'Weekly',
}

export const completeWithinLabelByValue: Record<number, string> = {
  1: '1 day later',
  2: '2 days later',
  3: '3 days later',
  7: '1 week later',
  99: 'End of day',
};

export const completeWithinOptionsByFrequency: Record<
  SurveyFrequency,
  ScheduleCompleteWithin[]
> = {
  [SurveyFrequency.Once]: [
    ScheduleCompleteWithin.OneDay,
    ScheduleCompleteWithin.TwoDays,
    ScheduleCompleteWithin.ThreeDays,
    ScheduleCompleteWithin.OneWeek,
    ScheduleCompleteWithin.EndOfDay,
  ],
  [SurveyFrequency.Daily]: [
    ScheduleCompleteWithin.OneDay,
    ScheduleCompleteWithin.EndOfDay,
  ],
  [SurveyFrequency.Weekly]: [
    ScheduleCompleteWithin.OneDay,
    ScheduleCompleteWithin.TwoDays,
    ScheduleCompleteWithin.ThreeDays,
    ScheduleCompleteWithin.OneWeek,
    ScheduleCompleteWithin.EndOfDay,
  ],
  [SurveyFrequency.Biweekly]: [
    ScheduleCompleteWithin.OneDay,
    ScheduleCompleteWithin.TwoDays,
    ScheduleCompleteWithin.ThreeDays,
    ScheduleCompleteWithin.OneWeek,
    ScheduleCompleteWithin.EndOfDay,
  ],
  [SurveyFrequency.Triweekly]: [
    ScheduleCompleteWithin.OneDay,
    ScheduleCompleteWithin.TwoDays,
    ScheduleCompleteWithin.ThreeDays,
    ScheduleCompleteWithin.OneWeek,
    ScheduleCompleteWithin.EndOfDay,
  ],
  [SurveyFrequency.Monthly]: [
    ScheduleCompleteWithin.OneDay,
    ScheduleCompleteWithin.TwoDays,
    ScheduleCompleteWithin.ThreeDays,
    ScheduleCompleteWithin.OneWeek,
    ScheduleCompleteWithin.EndOfDay,
  ],
};

export const defaultCompleteWithinOptionByFrequency: Record<
  SurveyFrequency,
  ScheduleCompleteWithin
> = {
  [SurveyFrequency.Once]: ScheduleCompleteWithin.OneDay,
  [SurveyFrequency.Daily]: ScheduleCompleteWithin.OneDay,
  [SurveyFrequency.Weekly]: ScheduleCompleteWithin.ThreeDays,
  [SurveyFrequency.Biweekly]: ScheduleCompleteWithin.ThreeDays,
  [SurveyFrequency.Triweekly]: ScheduleCompleteWithin.ThreeDays,
  [SurveyFrequency.Monthly]: ScheduleCompleteWithin.OneWeek,
};
