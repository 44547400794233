import React, { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, styled, useTheme, Paper as MuiPaper } from '@mui/material';
import { BasePage } from '@fdha/web-ui-library';

const Paper = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  width: '384px',
  padding: theme.spacing(4),
  border: 'none',
  boxShadow: theme.shadows[2],
}));

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
}));

interface LoginBaseProps {
  hideHeader?: boolean;
}

const LoginBase: FC<PropsWithChildren<LoginBaseProps>> = ({
  children,
  hideHeader,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/', { replace: true });
  };

  return (
    <BasePage sx={{ height: '100vh' }}>
      {hideHeader ? (
        <BasePage.BackButton handleClick={handleGoBack} />
      ) : (
        <FlexBox bgcolor={theme.palette.primary.main} height="150px">
          <img
            src="/images/Faeth_Logo_Large_White.png"
            alt="Faeth logo"
            data-testid="FAETH_LOGO_IMG"
          />
        </FlexBox>
      )}
      <FlexBox height={hideHeader ? '70%' : '100%'}>
        <Paper>{children}</Paper>
      </FlexBox>
    </BasePage>
  );
};

export default LoginBase;
