"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShipmentStatusLabelByValue = exports.getShipmentStatusDescriptionLabelByValue = void 0;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
var getI18nFirstSeparator = function (systemType) {
    return systemType === 'mobile' ? '.' : ':';
};
var getShipmentStatusDescriptionLabelByValue = function (systemType) {
    var _a;
    var i18nFirstSeparator = getI18nFirstSeparator(systemType);
    var i18nPrefix = "temporary".concat(i18nFirstSeparator);
    var statusUnavailable = {
        key: "".concat(i18nPrefix, "meals.deliveryShipmentStatusDescription.statusUnavailable"),
        fallback: "We can't display this delivery status right now, please check again later.",
    };
    return _a = {},
        _a[graphql_api_admin_1.TrackingStatus.BeingPrepared] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatusDescription.beingPrepared"),
            fallback: 'Your food is being prepared.',
        },
        _a[graphql_api_admin_1.TrackingStatus.Delivered] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatusDescription.delivered"),
            fallback: 'Your delivery arrived at your address.',
        },
        _a[graphql_api_admin_1.TrackingStatus.Failure] = statusUnavailable,
        _a[graphql_api_admin_1.TrackingStatus.Returned] = statusUnavailable,
        _a[graphql_api_admin_1.TrackingStatus.Transit] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatusDescription.inTransit"),
            fallback: 'Your delivery is coming to you.',
        },
        _a[graphql_api_admin_1.TrackingStatus.Unknown] = statusUnavailable,
        _a;
};
exports.getShipmentStatusDescriptionLabelByValue = getShipmentStatusDescriptionLabelByValue;
var getShipmentStatusLabelByValue = function (systemType) {
    var _a;
    var i18nFirstSeparator = getI18nFirstSeparator(systemType);
    var i18nPrefix = "temporary".concat(i18nFirstSeparator);
    return _a = {},
        _a[graphql_api_admin_1.TrackingStatus.BeingPrepared] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.beingPrepared"),
            fallback: 'Your food is being prepared',
        },
        _a[graphql_api_admin_1.TrackingStatus.Delivered] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.delivered"),
            fallback: 'Your order has been delivered',
        },
        _a[graphql_api_admin_1.TrackingStatus.Failure] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.statusUnavailable"),
            fallback: 'Delivery status unavailable',
        },
        _a[graphql_api_admin_1.TrackingStatus.Returned] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.statusUnavailable"),
            fallback: 'Delivery status unavailable',
        },
        _a[graphql_api_admin_1.TrackingStatus.Transit] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.inTransit"),
            fallback: 'Your delivery is coming to you',
        },
        _a[graphql_api_admin_1.TrackingStatus.Unknown] = {
            key: "".concat(i18nPrefix, "meals.deliveryShipmentStatus.unknown"),
            fallback: 'Tracking ID unavailable',
        },
        _a;
};
exports.getShipmentStatusLabelByValue = getShipmentStatusLabelByValue;
