"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslatedErrorMessages = void 0;
var useTranslation_1 = require("../useTranslation/useTranslation");
var useTranslatedErrorMessages = function (params) {
    if (params === void 0) { params = undefined; }
    var translate = (0, useTranslation_1.useTranslation)().translate;
    var requiredMessage = translate('validations:input.blank', 'This field cannot be left blank.');
    var validDateMessage = translate('validations:input.date.valid', 'Please type a valid date format.');
    var validEmailMessage = translate('validations:input.email.valid', 'Please type a valid email format.');
    var requiredEmailMessage = translate('validations:input.email.required', 'Email is a required field');
    var matchPasswordMessage = translate('validations:input.password.match', 'Password fields should match.');
    var passwordsDontMatchMessage = translate('validations:input.password.dontMatch', "Passwords don't match");
    var requiredPasswordMessage = translate('validations:input.password.required', 'Password is a required field');
    var incorrectPasswordMessage = translate('validations:input.password.incorrect', 'Incorrect password format.');
    var validPhoneMessage = translate('validations:input.phone', 'Please type a valid phone number.');
    var validTimeMessage = translate('validations:input.time.valid', 'Please insert a valid time');
    var timeNotInFutureMessage = translate('validations:input.time.notInFuture', 'Entry cannot be a time in the future');
    var endDateFutureMessage = translate('validations:input.date.future', 'End date must be in the future');
    var endDateAfterStartMessage = translate('validations:input.date.afterStart', 'End date must be after start date');
    var bhbMessage = translate('validations:input.bhb', 'Your BHB value needs to be higher than 0');
    var decimalMessage = translate('validations:input.decimal', 'Please type a valid decimal value');
    var validZipMessage = translate('validations:input.zip', 'Zip code must be exactly 5 numbers');
    var acceptTermsMessage = translate('validations:input.acceptedTos', 'Please agree to the terms and privacy policy to continue.');
    var decimalBetweenMessage = translate('surveys:steps.decimal.hint', "Please insert a value between ".concat(params === null || params === void 0 ? void 0 : params.min, " - ").concat(params === null || params === void 0 ? void 0 : params.max, " ").concat(params === null || params === void 0 ? void 0 : params.unit), params);
    var invalidCodeMessage = translate('validations:input.code.invalid', 'This code is invalid');
    var incorrectCodeMessage = translate('validations:input.code.incorrect', 'This code has an incorrect number of digits');
    return {
        requiredMessage: requiredMessage,
        validDateMessage: validDateMessage,
        validEmailMessage: validEmailMessage,
        requiredEmailMessage: requiredEmailMessage,
        matchPasswordMessage: matchPasswordMessage,
        passwordsDontMatchMessage: passwordsDontMatchMessage,
        incorrectPasswordMessage: incorrectPasswordMessage,
        requiredPasswordMessage: requiredPasswordMessage,
        validPhoneMessage: validPhoneMessage,
        validTimeMessage: validTimeMessage,
        timeNotInFutureMessage: timeNotInFutureMessage,
        endDateFutureMessage: endDateFutureMessage,
        endDateAfterStartMessage: endDateAfterStartMessage,
        bhbMessage: bhbMessage,
        decimalMessage: decimalMessage,
        validZipMessage: validZipMessage,
        acceptTermsMessage: acceptTermsMessage,
        decimalBetweenMessage: decimalBetweenMessage,
        invalidCodeMessage: invalidCodeMessage,
        incorrectCodeMessage: incorrectCodeMessage,
    };
};
exports.useTranslatedErrorMessages = useTranslatedErrorMessages;
