import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@fdha/web-ui-library';

import { useDialog, usePrevious } from '../../hooks';
import { Icon } from '../Icon/Icon';
import SpaceBetweenBox from '../SpaceBetweenBox/SpaceBetweenBox';
import { Typography } from '../Typography/Typography';

const Dialog = () => {
  const { dialog, closeDialog, updateDialogProps } = useDialog();

  const prevDialog = usePrevious(dialog);
  const isMounted = useRef(false);
  const theme = useTheme();

  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleActionBeforeConfirm = async () => {
    setIsConfirming(true);
    if (dialog.handleConfirm) {
      await dialog.handleConfirm();
    }

    if (isMounted.current) {
      setIsConfirming(false);
    }
  };

  const handleCancel = () => {
    if (dialog.handleCancel) {
      dialog.handleCancel();
    }
    closeDialog();
  };

  const handleDismiss = () => {
    if (dialog.handleDismiss) {
      dialog.handleDismiss();
    } else {
      handleCancel();
    }
  };

  const i18nKeyCancelButton =
    dialog.cancelButtonLabel &&
    (dialog.i18nKeyCancelButton ||
      `common:button.${dialog.cancelButtonLabel.toLowerCase()}`);

  const i18nKeyConfirmButton =
    dialog.confirmButtonLabel &&
    (dialog.i18nKeyConfirmButton ||
      `common:button.${dialog.confirmButtonLabel.toLowerCase()}`);

  /************ V2 updates *********************/

  const buttonColor = dialog.v2 ? 'primary' : 'secondary';
  const buttonSize = dialog.v2 ? 'small' : 'medium';
  const contentPadding = dialog.hidePadding
    ? { px: 0, pb: 0, pt: dialog.hideTopPadding ? 0 : undefined }
    : dialog.v2
    ? { paddingTop: 1, paddingBottom: 2 }
    : null;
  const actionsPadding = dialog.v2
    ? { paddingTop: 0, paddingBottom: 2 }
    : { paddingY: 2 };

  const renderDialogContent = () => {
    if (dialog.v2 && typeof dialog.content === 'string') {
      return (
        <Typography
          variant="body1"
          color={theme.palette.text.secondary}
          whiteSpace="break-spaces"
          i18nKey={dialog.i18nKeyContent}
        >
          {dialog.content}
        </Typography>
      );
    }
    return <>{dialog.content}</>;
  };

  /*********************************************/

  return (
    <MuiDialog
      open={dialog.visible}
      onClose={handleDismiss}
      fullWidth
      maxWidth={dialog.maxWidth}
      data-testid="DIALOG_ROOT"
    >
      <SpaceBetweenBox>
        <Box data-testid="DIALOG_BOX">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            p="16px 24px"
          >
            {dialog.showBackButton && (
              <IconButton
                onClick={() => {
                  dialog.handleBack && dialog.handleBack();
                  prevDialog && updateDialogProps(prevDialog);
                }}
                sx={{ mr: 1.5 }}
              >
                <Icon
                  size="large"
                  name="arrow-back-outline"
                  fill={theme.palette.primary.main}
                />
              </IconButton>
            )}
            {(dialog.title || dialog.i18nKeyTitle) && (
              <DialogTitle
                data-testid="DIALOG_TITLE"
                sx={{ maxWidth: '100%', padding: 0 }}
                variant="h5"
              >
                <Typography
                  variant="h5"
                  i18nKey={dialog?.i18nKeyTitle}
                  i18nParams={dialog?.i18nTitleParams}
                >
                  {dialog?.title}
                </Typography>
              </DialogTitle>
            )}
          </Box>
          {(dialog.subtitle || dialog.i18nKeySubtitle) && (
            <DialogContentText px={3}>
              <Typography
                variant="body1"
                i18nKey={dialog?.i18nKeySubtitle}
                i18nParams={dialog?.i18nSubtitleParams}
              >
                {dialog?.subtitle}
              </Typography>
            </DialogContentText>
          )}
        </Box>
        <Box display="flex">
          {dialog.headerButtons}
          {dialog.showCloseButton && (
            <IconButton onClick={handleCancel} sx={{ mr: 3 }}>
              <Icon
                size="large"
                name="close"
                fill={theme.palette.primary.main}
              />
            </IconButton>
          )}
        </Box>
      </SpaceBetweenBox>
      <DialogContent
        data-testid="DIALOG_CONTENT"
        sx={{
          overflow: dialog.hideScroll ? 'hidden' : 'auto',
          ...contentPadding,
        }}
      >
        {renderDialogContent()}
      </DialogContent>
      {(dialog.cancelButtonLabel || dialog.confirmButtonLabel) && (
        <DialogActions sx={{ ...actionsPadding }}>
          {dialog.cancelButtonLabel && (
            <Button
              onClick={handleCancel}
              color="primary"
              data-testid={`${dialog.cancelButtonLabel}_DIALOG_BUTTON`}
              size={buttonSize}
              i18nKey={i18nKeyCancelButton}
            >
              {dialog.cancelButtonLabel}
            </Button>
          )}
          {dialog.confirmButtonLabel && (
            <Button
              disabled={isConfirming}
              onClick={handleActionBeforeConfirm}
              color={buttonColor}
              autoFocus
              variant="contained"
              sx={{ paddingY: '8px', paddingX: '22px' }}
              data-testid={`${dialog.confirmButtonLabel}_DIALOG_BUTTON`}
              size={buttonSize}
              i18nKey={i18nKeyConfirmButton}
            >
              {dialog.confirmButtonLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
