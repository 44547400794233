"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShipmentCurrentStep = exports.shipmentStatusSteps = exports.getPriorityShipmentStatus = exports.joinMealTypes = exports.getSectionTypeLabel = exports.getConcatWeekdaysOnDiet = exports.sortWeekDays = exports.groupDishes = exports.getAllMenuOptionSelections = exports.getAllMenuOptionSelectionsTags = exports.addToList = exports.isFull = exports.calculateAmountToSelect = exports.getQuantitySelected = exports.isQuantityExceeded = void 0;
var lodash_1 = require("lodash");
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
var types_1 = require("../../types");
var shipmentStatusPriorityOrder = [
    types_1.DeliveryShipmentStatus.Transit,
    types_1.DeliveryShipmentStatus.BeingPrepared,
    types_1.DeliveryShipmentStatus.Returned,
    types_1.DeliveryShipmentStatus.Failure,
    types_1.DeliveryShipmentStatus.Unknown,
    types_1.DeliveryShipmentStatus.Delivered,
];
/**
 * Determines if the total quantity of selected dishes exceeds the specified limit.
 *
 * @param menu - Array of menu option selections.
 * @param amount - Maximum allowed quantity of selected dishes.
 * @returns `true` if the total quantity exceeds the specified amount, otherwise `false`.
 */
var isQuantityExceeded = function (menu, amount) {
    var quantity = (0, exports.getQuantitySelected)(menu);
    return quantity > amount;
};
exports.isQuantityExceeded = isQuantityExceeded;
/**
 * Calculates the total quantity of selected dishes from a list of menu options.
 *
 * @param menu - Array of menu option selections.
 * @returns The total quantity of selected dishes.
 */
var getQuantitySelected = function (menu) {
    return menu.reduce(function (actual, val) {
        return actual + val.quantity;
    }, 0);
};
exports.getQuantitySelected = getQuantitySelected;
/**
 * Calculates the amount of selections that the patient needs to order.
 * Checking if the total quantity of selected dishes exceeds the specified limit.
 *
 * @param values - Array of menu option selections.
 * @param defaultValue - Default amount of dishes to be selected.
 * @returns The total quantity of dishes the patient needs to select.
 */
var calculateAmountToSelect = function (values, defaultValue) {
    return (0, exports.isQuantityExceeded)(values, defaultValue)
        ? (0, exports.getQuantitySelected)(values)
        : defaultValue;
};
exports.calculateAmountToSelect = calculateAmountToSelect;
/**
 * Checks if the total quantity of selected dishes equals the specified total.
 *
 * @param menu - Array of menu option selections.
 * @param total - Total quantity to be checked against.
 * @returns `true` if the total quantity equals the specified total, otherwise `false`.
 */
var isFull = function (menu, total) {
    return (0, exports.getQuantitySelected)(menu) === total;
};
exports.isFull = isFull;
/**
 * Adds menu option selections to the list of dishes based on meal type.
 *
 * @param mealType - Type of the meal.
 * @param dishes - Array of menu option selections to be updated.
 * @param menu - Array of menu options to be added.
 */
var addToList = function (mealType, dishes, menu) {
    menu.forEach(function (item) {
        var index = dishes.findIndex(function (dish) { return dish.dishId === item.dishId; });
        if (index === -1) {
            dishes.push(__assign(__assign({}, item), { mealType: [mealType] }));
        }
        else {
            var oldDish = dishes[index];
            if (!oldDish) {
                return;
            }
            if (!oldDish.mealType) {
                throw new Error("Meal type is missing for dish ".concat(oldDish.dishId));
            }
            dishes[index] = __assign(__assign({}, oldDish), { quantity: oldDish.quantity + item.quantity, mealType: __spreadArray(__spreadArray([], oldDish.mealType, true), [mealType], false) });
        }
    });
};
exports.addToList = addToList;
/**
 * Returns all tags from the given menu option selections.
 * @param menuOptionSelections - Menu option selections to get tags from.
 * @returns All distinct tags from the given menu option selections sorted by name asc.
 */
var getAllMenuOptionSelectionsTags = function (menuOptionSelections) {
    var tags = menuOptionSelections.flatMap(function (selection) { var _a, _b; return (_b = (_a = selection.tagsByCategory) === null || _a === void 0 ? void 0 : _a.flatMap(function (tag) { return tag.tags; })) !== null && _b !== void 0 ? _b : []; });
    return (0, lodash_1.sortBy)((0, lodash_1.uniqBy)(tags, 'id'), 'name');
};
exports.getAllMenuOptionSelectionsTags = getAllMenuOptionSelectionsTags;
/**
 * Gets all menu option selections of entree dishes and snacks.
 * @param menuOptions - Menu options.
 * @returns all menu option selections of entree dishes and snacks.
 */
var getAllMenuOptionSelections = function (menuOptions) {
    return menuOptions.entreeDishes.concat(menuOptions.snacks);
};
exports.getAllMenuOptionSelections = getAllMenuOptionSelections;
/**
 * Groups dishes by meal type from the given menu selections.
 *
 * @param menuOptionSelection - Menu selections to be grouped.
 * @returns Grouped menu options by entree dishes and snacks or undefined if menu selections are empty.
 */
var groupDishes = function (menuOptionSelection) {
    if (!menuOptionSelection) {
        return;
    }
    var entreeDishes = [];
    var snacks = [];
    Object.entries(menuOptionSelection).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        if (!Array.isArray(value)) {
            return;
        }
        var mealTypeKey = key;
        if ([graphql_api_admin_1.MealType.Breakfast, graphql_api_admin_1.MealType.Lunch, graphql_api_admin_1.MealType.Dinner].includes(mealTypeKey)) {
            (0, exports.addToList)(mealTypeKey, entreeDishes, value);
        }
        else {
            (0, exports.addToList)(mealTypeKey, snacks, value);
        }
    });
    var options = {
        entreeDishes: entreeDishes.sort(function (a, b) { return a.name.localeCompare(b.name); }),
        snacks: snacks.sort(function (a, b) { return a.name.localeCompare(b.name); }),
    };
    return options;
};
exports.groupDishes = groupDishes;
/**
 * Sorts the array of weekdays from sun to sat.
 *
 * @param weekdays - Array of weekdays to be sorted.
 * @returns Sorted array of weekdays.
 */
var sortWeekDays = function (weekdays) {
    var data = (0, lodash_1.uniq)(weekdays);
    var weekdaysOrder = [
        graphql_api_admin_1.WeekDay.Sun,
        graphql_api_admin_1.WeekDay.Mon,
        graphql_api_admin_1.WeekDay.Tue,
        graphql_api_admin_1.WeekDay.Wed,
        graphql_api_admin_1.WeekDay.Thu,
        graphql_api_admin_1.WeekDay.Fri,
        graphql_api_admin_1.WeekDay.Sat,
    ];
    return (0, lodash_1.orderBy)(data, function (day) { return weekdaysOrder.indexOf(day); });
};
exports.sortWeekDays = sortWeekDays;
/**
 * Concatenates the weekdays on diet from multiple user delivery menus.
 *
 * @param userDeliveryMenus - Array of user delivery menus.
 * @returns Concatenated and sorted (sun to sat) array of weekdays on diet.
 */
var getConcatWeekdaysOnDiet = function (userDeliveryMenus) {
    var _a, _b, _c, _d;
    var firstUserDeliveryWeekdaysOnDiet = (_b = (_a = userDeliveryMenus[0]) === null || _a === void 0 ? void 0 : _a.weekdaysOnDiet) !== null && _b !== void 0 ? _b : [];
    var secondUserDeliveryWeekdaysOnDiet = (_d = (_c = userDeliveryMenus[1]) === null || _c === void 0 ? void 0 : _c.weekdaysOnDiet) !== null && _d !== void 0 ? _d : [];
    var weekdaysOnDiet = firstUserDeliveryWeekdaysOnDiet.concat(secondUserDeliveryWeekdaysOnDiet);
    weekdaysOnDiet = (0, exports.sortWeekDays)(weekdaysOnDiet);
    return weekdaysOnDiet;
};
exports.getConcatWeekdaysOnDiet = getConcatWeekdaysOnDiet;
/**
 * Returns the label for the given section type, optionally considering if there are sides.
 *
 * @param type - Type of the section (entreeDishes or snacks).
 * @param hasSides - Whether the section has sides.
 * @returns Label for the section type.
 */
var getSectionTypeLabel = function (type, hasSides) {
    if (type === 'snacks') {
        if (hasSides) {
            return 'Snacks and Sides';
        }
        return 'Snacks';
    }
    return 'Entree Dishes';
};
exports.getSectionTypeLabel = getSectionTypeLabel;
/**
 * Joins and capitalizes meal types, using a translation function for each meal type.
 *
 * @param types - Array of meal types to be joined and capitalized.
 * @param translate - Function to translate meal type keys to their respective values.
 * @returns A string of joined and capitalized meal types.
 */
var joinMealTypes = function (types, translate) {
    return types
        .map(function (type) { return (0, lodash_1.capitalize)(translate("meals.mealType.".concat(type), type)); })
        .join(', ');
};
exports.joinMealTypes = joinMealTypes;
/**
 * Gets the most priority shipment status from the given list of shipment statuses.
 *
 * @param status - Array of shipment statuses.
 * @returns Most priority shipment status.
 */
var getPriorityShipmentStatus = function (status) {
    var mostPriority = status.sort(function (a, b) {
        return (shipmentStatusPriorityOrder.indexOf(a) -
            shipmentStatusPriorityOrder.indexOf(b));
    })[0];
    return mostPriority;
};
exports.getPriorityShipmentStatus = getPriorityShipmentStatus;
exports.shipmentStatusSteps = [
    types_1.DeliveryShipmentStatus.BeingPrepared,
    types_1.DeliveryShipmentStatus.Transit,
    types_1.DeliveryShipmentStatus.Delivered,
];
/**
 * Gets the current step of shipment statuses
 * @param status current shipment status
 * @returns current step of shipment statuses
 */
var getShipmentCurrentStep = function (status) {
    return exports.shipmentStatusSteps.indexOf(status) + 1;
};
exports.getShipmentCurrentStep = getShipmentCurrentStep;
