import { AlertProps } from '@mui/material';
import React, { useState, useContext, useEffect, useCallback } from 'react';

import Snackbar, { SnackbarVariant } from '../components/Snackbar/Snackbar';

type VisibleSnackbarContent = SnackbarContent & {
  visible: boolean;
};

export type SnackbarContent = {
  message: React.ReactNode;
  i18nKey?: string;
  severity?: AlertProps['severity'];
  variant?: SnackbarVariant;
  closeOnClickOutside?: boolean;
  closeAutomatically?: boolean;
  v2?: boolean;
};

interface ContextProps {
  snackbar: VisibleSnackbarContent;
  showSnackbar: (snackbar: SnackbarContent) => void;
  showSnackbarV2: (snackbar: SnackbarContent) => void;
  closeSnackbar: () => void;
}

const initialState: VisibleSnackbarContent = {
  message: '',
  i18nKey: '',
  severity: 'success',
  visible: false,
  variant: 'default',
  closeOnClickOutside: false,
  closeAutomatically: true,
  v2: false,
};

const Context = React.createContext<ContextProps>({
  snackbar: initialState,
  showSnackbar: () => {},
  showSnackbarV2: () => {},
  closeSnackbar: () => {},
});

export const SnackbarProvider = ({ ...props }) => {
  const [snackbar, setSnackbar] =
    useState<VisibleSnackbarContent>(initialState);

  const handleShowSnackbar = (newSnackbarContent: SnackbarContent) => {
    setSnackbar({
      ...initialState,
      ...newSnackbarContent,
      visible: true,
    });
  };

  const handleShowSnackbarV2 = (newSnackbarContent: SnackbarContent) => {
    handleShowSnackbar({
      ...newSnackbarContent,
      v2: true,
    });
  };

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar({ ...snackbar, visible: false });
  }, [snackbar]);

  useEffect(() => {
    let timeoutId: any;
    if (
      snackbar.visible &&
      snackbar.variant !== 'loading' &&
      snackbar.closeAutomatically
    ) {
      timeoutId = setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [snackbar, handleCloseSnackbar]);

  const SnackbarContextProvider = Context.Provider;
  return (
    <SnackbarContextProvider
      value={{
        snackbar,
        showSnackbar: handleShowSnackbar,
        showSnackbarV2: handleShowSnackbarV2,
        closeSnackbar: handleCloseSnackbar,
      }}
    >
      {props.children}
      <Snackbar />
    </SnackbarContextProvider>
  );
};

export const useSnackbar = () => {
  const { snackbar, showSnackbar, showSnackbarV2, closeSnackbar } =
    useContext(Context);
  return {
    snackbar,
    showSnackbar,
    showSnackbarV2,
    closeSnackbar,
  };
};
