"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureFlag = exports.FeatureFlagsProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
/* Getting from admin because we don't have a graphql-api-common package
  It's work well for any other package that has the featureFlags query returning
  the same data structure
 */
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
var Context = (0, react_1.createContext)({
    isFeatureEnabled: function () { return false; },
});
var FeatureFlagsProvider = function (_a) {
    var children = _a.children;
    var Provider = Context.Provider;
    var _b = (0, graphql_api_admin_1.useGetFeatureFlagsQuery)(), data = _b.data, loading = _b.loading;
    var flags = (0, react_1.useMemo)(function () { var _a; return (_a = data === null || data === void 0 ? void 0 : data.featureFlags) !== null && _a !== void 0 ? _a : []; }, [data === null || data === void 0 ? void 0 : data.featureFlags]);
    var isFeatureEnabled = (0, react_1.useCallback)(function (name, defaultValue) {
        var _a;
        if (defaultValue === void 0) { defaultValue = false; }
        var flag = flags.find(function (f) { return f.name === name; });
        return (_a = flag === null || flag === void 0 ? void 0 : flag.enabled) !== null && _a !== void 0 ? _a : defaultValue;
    }, [flags]);
    return ((0, jsx_runtime_1.jsx)(Provider, __assign({ value: { isFeatureEnabled: isFeatureEnabled, isLoading: loading } }, { children: children })));
};
exports.FeatureFlagsProvider = FeatureFlagsProvider;
var useFeatureFlag = function () { return (0, react_1.useContext)(Context); };
exports.useFeatureFlag = useFeatureFlag;
