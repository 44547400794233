import React from 'react';
import { Loader } from '@fdha/web-ui-library';
import { Box, List, ListItem } from '@mui/material';

import { LoadMore } from '../LoadMore/LoadMore';

interface InfiniteScrollListProps<T> {
  items: T[];
  isLoading: boolean;
  isLoadingMore: boolean;
  disableRowGap?: boolean;
  renderEmptyState?: () => React.ReactNode;
  renderLoading?: () => React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode;
  loadMore: () => void;
}

export function InfiniteScrollList<T>({
  items,
  isLoading,
  isLoadingMore,
  disableRowGap,
  renderItem,
  renderEmptyState,
  renderLoading,
  loadMore,
}: InfiniteScrollListProps<T>) {
  if (isLoading) {
    if (renderLoading) {
      return <>{renderLoading()}</>;
    } else {
      return (
        <Box padding={1}>
          <Loader size={20} />
        </Box>
      );
    }
  }

  const cursor = items.length;

  return (
    <List
      disablePadding
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: disableRowGap ? 0 : 2,
      }}
    >
      {!items.length
        ? renderEmptyState && renderEmptyState()
        : items.map((item, index) => (
            <ListItem key={index} disablePadding>
              <Box flex="1 1 auto" minWidth={0}>
                {renderItem(item, index)}
              </Box>
            </ListItem>
          ))}
      <LoadMore
        onLoadMore={loadMore}
        listLength={cursor}
        isLoadingMore={isLoadingMore}
      />
    </List>
  );
}
