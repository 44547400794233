import React from 'react';

import { Login, LoginProps } from './Login';
import { ForgotPassword, ForgotPasswordProps } from './ForgotPassword';
import { ResetPassword, ResetPasswordProps } from './ResetPassword';
import {
  ProfileChangePassword,
  ProfileChangePasswordProps,
} from './ProfileChangePassword';
import { ChangePassword, ChangePasswordProps } from './ChangePassword';
import { SMSChallengeCode, SMSChallengeCodeProps } from './SMSChallengeCode';

// IMPORTANT: Wrappers only, any changes should be done in the component itself.

export const ChangePasswordV2: React.FC<Omit<ChangePasswordProps, 'v2'>> = (
  props
) => {
  return <ChangePassword v2={true} {...props} />;
};

export const LoginV2: React.FC<Omit<LoginProps, 'v2'>> = (props) => {
  return <Login v2={true} {...props} />;
};

export const ForgotPasswordV2: React.FC<Omit<ForgotPasswordProps, 'v2'>> =
  () => {
    return <ForgotPassword v2={true} />;
  };

export const ResetPasswordV2: React.FC<Omit<ResetPasswordProps, 'v2'>> = (
  props
) => {
  return <ResetPassword v2={true} {...props} />;
};

export const ProfileChangePasswordV2: React.FC<
  Omit<ProfileChangePasswordProps, 'v2'>
> = (props) => {
  return <ProfileChangePassword v2={true} {...props} />;
};

export const SMSChallengeCodeV2: React.FC<Omit<SMSChallengeCodeProps, 'v2'>> =
  () => {
    return <SMSChallengeCode v2={true} />;
  };
