"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useActivityDetector = exports.ActivityDetectorProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = __importStar(require("react"));
var auth_1 = require("@aws-amplify/auth");
var date_fns_1 = require("date-fns");
var createActivityDetector = require('activity-detector').default;
var TIME_TO_IDLE = 3600000;
var Context = (0, react_1.createContext)({
    init: function () { },
    setActivityInProgress: function () { },
});
var ActivityDetectorProvider = function (_a) {
    var children = _a.children, onSignOut = _a.onSignOut;
    var initializedRef = react_1.default.useRef(false);
    var activitiesInProgressRef = react_1.default.useRef([]);
    var _b = (0, react_1.useState)(null), activityDetector = _b[0], setActivityDetector = _b[1];
    (0, react_1.useEffect)(function () {
        return function () {
            activityDetector === null || activityDetector === void 0 ? void 0 : activityDetector.stop();
        };
    }, [activityDetector]);
    var doSignOut = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, auth_1.signOut)()];
                case 1:
                    _a.sent();
                    onSignOut && onSignOut();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('error signing out: ', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [onSignOut]);
    var init = (0, react_1.useCallback)(function (config) {
        // Ignore if it's already initialized
        if (initializedRef.current) {
            return;
        }
        var _a = config || {}, lastLoginTime = _a.lastLoginTime, rememberMe = _a.rememberMe;
        // We don't want to logout user when this flag is true
        if (rememberMe) {
            initializedRef.current = true;
            return;
        }
        var lastLogin = lastLoginTime
            ? (0, date_fns_1.differenceInHours)(new Date(), new Date(lastLoginTime))
            : 0;
        if (lastLogin >= 24) {
            doSignOut();
        }
        else {
            var activityDetector_1 = createActivityDetector({
                timeToIdle: TIME_TO_IDLE,
                inactivityEvents: [],
                autoInit: false,
            });
            setActivityDetector(activityDetector_1);
            activityDetector_1.init();
            initializedRef.current = true;
            activityDetector_1.on('idle', function () {
                doSignOut();
            });
        }
    }, [setActivityDetector, doSignOut]);
    var setActivityInProgress = (0, react_1.useCallback)(function (activity, isInProgress) {
        if (isInProgress) {
            activitiesInProgressRef.current.push(activity);
            activityDetector === null || activityDetector === void 0 ? void 0 : activityDetector.stop();
        }
        else {
            var newActivities = activitiesInProgressRef.current.filter(function (act) { return act !== activity; });
            // Starting activity detector only once when all activities are finished
            if (newActivities.length === 0 &&
                activitiesInProgressRef.current.length > 0) {
                activityDetector === null || activityDetector === void 0 ? void 0 : activityDetector.init();
                activityDetector === null || activityDetector === void 0 ? void 0 : activityDetector.on('idle', function () {
                    doSignOut();
                });
            }
            activitiesInProgressRef.current = newActivities;
        }
    }, [activityDetector, doSignOut]);
    return ((0, jsx_runtime_1.jsx)(Context.Provider, __assign({ value: { init: init, setActivityInProgress: setActivityInProgress } }, { children: children })));
};
exports.ActivityDetectorProvider = ActivityDetectorProvider;
var useActivityDetector = function () { return (0, react_1.useContext)(Context); };
exports.useActivityDetector = useActivityDetector;
