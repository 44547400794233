import { FormikState } from 'formik';
import { resetPassword, updatePassword } from '@aws-amplify/auth';
import { SnackbarContent, useDialog, useSnackbar } from '@fdha/web-ui-library';
import {
  getHintMessage,
  PasswordRule,
  PasswordRuleUtils,
} from '@fdha/web-auth';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslatedErrorMessages } from '@fdha/common-hooks';

import ProfileChangePasswordForm, {
  ProfileChangePasswordValuesProps,
} from './ProfileChangePasswordForm';
import { ProfileChangePasswordModal } from './ProfileChangePasswordModal';

export interface ProfileChangePasswordProps {
  email?: string;
  hideLogoutModal?: boolean;
  v2?: boolean;
}

export const ProfileChangePassword: FC<ProfileChangePasswordProps> = ({
  email,
  hideLogoutModal,
  v2,
}) => {
  const isMounted = useRef(false);
  const navigate = useNavigate();
  const { showSnackbar, showSnackbarV2 } = useSnackbar();
  const { openDialog, openDialogV2, closeDialog } = useDialog();
  const { matchPasswordMessage, incorrectPasswordMessage } =
    useTranslatedErrorMessages();

  const initialErrors = {
    currentPasswordError: '',
    passwordTipsError: '',
    newPasswordError: '',
    confirmPasswordError: '',
  };

  const [passwordHint, setPasswordHint] = useState('');
  const [passwordHintV2, setPasswordHintV2] = useState<
    PasswordRule[] | undefined
  >(undefined);
  const [errors, setErrors] = useState(initialErrors);
  const [isLinkDisabled, setIsLinkDisabled] = useState<boolean>(false);

  const snackbarInfo: SnackbarContent = {
    message: 'Sorry, something went wrong. Please try again.',
    severity: 'error',
    i18nKey: 'common:snackbar.somethingWentWrong',
  };

  useEffect(() => {
    isMounted.current = true;

    try {
      (async () => {
        if (v2) {
          const hint = await PasswordRuleUtils.getPasswordHintMessageV2();
          if (isMounted.current) {
            setPasswordHintV2(hint);
          }
        } else {
          const hint = await getHintMessage();
          if (isMounted.current) {
            setPasswordHint(hint);
          }
        }
      })();

      return () => {
        isMounted.current = false;
      };
    } catch (error) {
      console.error(error);
    }
  }, [v2]);

  const handleForgotPassword = async () => {
    if (isLinkDisabled) {
      return;
    }

    try {
      if (!v2) {
        setIsLinkDisabled(true);

        setTimeout(() => {
          if (isMounted.current) {
            setIsLinkDisabled(false);
          }
        }, 15000);
      }

      await resetPassword({ username: email || '' });
      if (v2) {
        navigate('/reset-password', { state: { email }, replace: true });
      } else {
        openDialog({
          title:
            'We just sent you an email with the instructions to reset your password.',
          content: null,
          confirmButtonLabel: 'Ok',
          cancelButtonLabel: '',
          handleConfirm: async () => {
            closeDialog();
          },
        });
      }
    } catch (error: any) {
      const message =
        error.code === 'UserNotFoundException'
          ? {
              i18nKey: 'login:forgotPassword.errors.userNotFoundException',
              text: 'No account associated with this email address.',
            }
          : {
              i18nKey: 'common:snackbar.somethingWentWrong',
              text: 'Sorry, something went wrong. Please try again.',
            };

      showSnackbar({
        severity: 'error',
        message: message.text,
        i18nKey: message.i18nKey,
      });
    }
  };

  const handleSubmit = async (
    values: ProfileChangePasswordValuesProps,
    resetForm: (
      nextState?:
        | Partial<FormikState<ProfileChangePasswordValuesProps>>
        | undefined
    ) => void
  ) => {
    setErrors(initialErrors);
    setPasswordHint('');

    const oldPassword = values.currentPassword.trim();
    const newPassword = values.newPassword.trim();
    const confirmNewPassword = values.confirmNewPassword.trim();

    const rulesErrorList =
      PasswordRuleUtils.getPasswordRulesListError(newPassword);

    if (rulesErrorList) {
      setErrors({ ...initialErrors, passwordTipsError: rulesErrorList });
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setErrors({
        ...initialErrors,
        confirmPasswordError: matchPasswordMessage,
      });
      return;
    }

    try {
      await updatePassword({
        oldPassword,
        newPassword,
      });
      resetForm();
      if (v2) {
        hideLogoutModal
          ? showSnackbarV2({
              severity: 'success',
              message: 'New password saved!',
              i18nKey: 'profile:snackbar.newPassword',
            })
          : openDialogV2({
              title: 'Password updated.',
              content: <ProfileChangePasswordModal />,
              i18nKeyTitle: 'profile:changePassword.dialog.title',
            });
      } else {
        showSnackbar({
          severity: 'success',
          message: 'New password saved!',
        });
      }
    } catch (e: any) {
      if (e.code === 'InvalidPasswordException') {
        setErrors({
          ...initialErrors,
          newPasswordError: incorrectPasswordMessage,
        });
      } else if (
        e.code === 'NotAuthorizedException' ||
        e.code === 'InvalidParameterException'
      ) {
        setErrors({
          ...initialErrors,
          currentPasswordError: incorrectPasswordMessage,
        });
      } else {
        v2 ? showSnackbarV2(snackbarInfo) : showSnackbar(snackbarInfo);
      }
    }
  };

  return (
    <ProfileChangePasswordForm
      hint={passwordHint}
      hintV2={passwordHintV2}
      errors={errors}
      handleSubmit={handleSubmit}
      handleForgotPassword={handleForgotPassword}
      isLinkDisabled={isLinkDisabled}
      v2={v2}
    />
  );
};
