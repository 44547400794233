import { ApolloError } from '@apollo/client';
import { FormikErrors, FormikTouched, getIn } from 'formik';
import { GraphQLError } from 'graphql';

const handledErrorCodes = ['BAD_USER_INPUT', 'NOT_FOUND', 'FORBIDDEN'];

export const parseBackendError = (error: any, customMessage: string) => {
  const defaultMessage = 'Sorry, something went wrong. Please try again later.';

  const handledError = error.graphQLErrors.find(
    (graphqlError: GraphQLError) => {
      if (typeof graphqlError.extensions.code === 'string') {
        return handledErrorCodes.includes(graphqlError.extensions.code);
      } else {
        return undefined;
      }
    }
  );
  if (error instanceof ApolloError) customMessage = error?.message;

  return handledError?.message || customMessage || defaultMessage;
};

export function getFormikError<T>(
  name: string,
  errors: FormikErrors<T>,
  touched: FormikTouched<T>
): string {
  const errorMsg = getIn(errors, name);
  const hasTouched = getIn(touched, name);

  return hasTouched && errorMsg ? errorMsg : null;
}
