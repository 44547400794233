import React, { PropsWithChildren } from 'react';
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Breadcrumb, BreadcrumbProps } from '../Breadcrumb/Breadcrumb';
import {
  LinearProgress,
  LinearProgressProps,
} from '../LinearProgress/LinearProgress';
import { Typography } from '../Typography/Typography';

import { ContentSize, HORIZONTAL_PADDING, contentSizes } from './utils';

/* Navigation: 
  Close button or Breadcrumb
  Progress bar 
 */

export type NavigationCloseAction = 'close' | 'cancel';

export type NavigationProgress = LinearProgressProps;

export interface NavigationPropsBase {
  type: 'close' | 'breadcrumb';
  isLoading?: boolean;
  progress?: NavigationProgress;
}

export interface CloseNavigationProps extends NavigationPropsBase {
  type: 'close';
  closeProps: {
    onBeforeClose?: () => Promise<NavigationCloseAction>;
    onClose: () => void;
  };
}

export interface BreadcrumbNavigationProps extends NavigationPropsBase {
  type: 'breadcrumb';
  breadcrumbProps: BreadcrumbProps;
}

export type BasePageV2NavigationProps =
  | CloseNavigationProps
  | BreadcrumbNavigationProps;

export const BasePageV2Navigation: React.FC<BasePageV2NavigationProps> = (
  props
) => {
  const { isLoading, type, progress } = props;

  const renderClose = () => {
    if (type !== 'close') {
      return;
    }

    const { onBeforeClose, onClose } = props.closeProps;

    const handleClose = async () => {
      if (onBeforeClose) {
        const result = await onBeforeClose();
        if (result === 'close') {
          onClose();
        }
      } else {
        onClose();
      }
    };

    return (
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          startEvaIcon={{ name: 'close-outline' }}
          color="secondary"
          onClick={handleClose}
          data-testid="CLOSE_BUTTON"
          i18nKey="common:button.close"
        >
          Close
        </Button>
      </Box>
    );
  };

  const renderBreadcrumb = () => {
    if (type !== 'breadcrumb') {
      return;
    }
    return (
      <Box marginTop={3}>
        <Breadcrumb {...props.breadcrumbProps} />
      </Box>
    );
  };

  return (
    <>
      {type === 'close' ? renderClose() : renderBreadcrumb()}
      {progress && (
        <Box mt={4}>
          {isLoading ? (
            <Skeleton variant="rectangular" />
          ) : (
            <LinearProgress {...progress} />
          )}
        </Box>
      )}
    </>
  );
};

/* Header:
  Logo
  Back button
  Title
  Subtitle
  Right button
 */

export interface BasePageV2HeaderProps {
  logoUrl?: string;
  showBack?: boolean;
  onClickBack?: () => void;
  title?: string | null;
  i18nKeyTitle?: string;
  i18nTitleParams?: { [key: string]: string };
  i18nKeySubtitle?: string;
  i18nSubtitleParams?: { [key: string]: string };
  subtitle?: string;
  rightButton?: JSX.Element;
  centeredHeader?: boolean;
  isLoading?: boolean;
}

export const BasePageV2Header: React.FC<BasePageV2HeaderProps> = ({
  logoUrl,
  showBack = true,
  onClickBack,
  title,
  i18nKeyTitle,
  i18nTitleParams,
  i18nKeySubtitle,
  i18nSubtitleParams,
  subtitle,
  rightButton,
  centeredHeader = false,
  isLoading,
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems={centeredHeader ? 'center' : 'flex-start'}
      mt={2}
    >
      {logoUrl && (
        <img
          style={{ marginBottom: theme.spacing(4) }}
          src={logoUrl}
          alt="Faeth Logo"
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        width={centeredHeader ? 'fit-content' : '100%'}
        justifyContent={centeredHeader ? 'center' : 'space-between'}
      >
        <Box display="flex" flex={1}>
          {showBack && (
            <IconButton onClick={onClickBack}>
              <Icon
                data-testid="BASE_PAGE_BACK_BUTTON"
                name="arrow-back-outline"
                size="xlarge"
                fill={theme.palette.primary.main}
              />
            </IconButton>
          )}
          <>
            {isLoading ? (
              <Skeleton variant="rectangular" height="60px" sx={{ flex: 1 }} />
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems={centeredHeader ? 'center' : 'flex-start'}
              >
                <Typography
                  variant="h4"
                  i18nKey={i18nKeyTitle}
                  i18nParams={i18nTitleParams}
                  data-testid="BASE_PAGE_TITLE"
                >
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  i18nKey={i18nKeySubtitle}
                  i18nParams={i18nSubtitleParams}
                  color={theme.palette.text.secondary}
                  data-testid="BASE_PAGE_SUBTITLE"
                >
                  {subtitle}
                </Typography>
              </Box>
            )}
          </>
        </Box>
        <Box ml={1}>{rightButton}</Box>
      </Box>
    </Box>
  );
};

// Body

export interface BasePageV2BodyProps {
  fullHeight?: boolean;
}

export const BasePageV2Body: React.FC<PropsWithChildren<BasePageV2BodyProps>> =
  ({ fullHeight = true, children }) => {
    return (
      <Box
        marginTop={3}
        flexGrow={fullHeight ? 1 : 'unset'}
        pb={fullHeight ? 4 : 0}
      >
        {children}
      </Box>
    );
  };

export type BasePageV2Type = 'default' | 'cover' | 'notFound';

export interface BasePageV2BaseProps {
  type: BasePageV2Type;
  contentSize?: ContentSize;
}

interface BasePageV2DefaultProps extends BasePageV2BaseProps {
  type: 'default';
  verticallyCentered?: boolean;
}

interface BasePageV2CoverProps extends BasePageV2BaseProps {
  type: 'cover';
  coverImageUrl?: string;
}

interface BasePageV2NotFoundProps extends BasePageV2BaseProps {
  type: 'notFound';
  handleNotFoundRedirect?: () => void;
}

export type BasePageV2Props =
  | BasePageV2CoverProps
  | BasePageV2DefaultProps
  | BasePageV2NotFoundProps;

export const BasePageV2: React.FC<PropsWithChildren<BasePageV2Props>> = ({
  contentSize = 'xlarge',
  children,
  ...props
}) => {
  const theme = useTheme();

  const size = contentSizes[contentSize];
  const isBelowMaxWidth = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(size)
  );
  const maxWidth = isBelowMaxWidth ? '100%' : size;

  const renderContent = () => {
    const verticallyCentered =
      props.type === 'default' && props.verticallyCentered;

    return (
      <Box
        display="flex"
        mx="auto"
        maxWidth={maxWidth}
        paddingX={`${HORIZONTAL_PADDING}px`}
        flex={1}
        height="100%"
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Box
          flex={1}
          minWidth={0}
          display="flex"
          flexDirection="column"
          margin={verticallyCentered ? 'auto' : 'unset'}
          data-testid="BASE_PAGE_V2_CONTENT"
        >
          {children}
        </Box>
      </Box>
    );
  };

  const renderContentWithCover = () => {
    if (props.type === 'cover') {
      return (
        <Box
          height="350px"
          width="100%"
          position="relative"
          sx={{
            backgroundImage: `url(${props.coverImageUrl})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Box marginTop="219px">{renderContent()}</Box>
        </Box>
      );
    }
  };

  const renderNotFound = () => {
    if (props.type === 'notFound') {
      return (
        <Stack width="100%" alignItems="center" spacing={2} marginTop={10}>
          <Icon
            name="eye-off-outline"
            size="xlarge"
            fill={theme.palette.text.secondary}
          />
          <Typography variant="h4" i18nKey="home:notFound.title">
            This page cannot be found.
          </Typography>
          <Typography variant="h6" i18nKey="home:notFound.description">
            We’re sorry, but we can’t find the page you’re looking for. Please
            head back to the home page.
          </Typography>
          <Button
            variant="contained"
            onClick={props.handleNotFoundRedirect}
            i18nKey="home:notFound.button"
          >
            Go to the home page
          </Button>
        </Stack>
      );
    }
  };

  return (
    <Box
      display="flex"
      id="basePage"
      flexDirection="column"
      flex="1 1 0"
      overflow="auto"
    >
      <Box display="flex" minWidth={theme.breakpoints.values.sm} height="100%">
        {props.type === 'notFound'
          ? renderNotFound()
          : props.type === 'cover'
          ? renderContentWithCover()
          : renderContent()}
      </Box>
    </Box>
  );
};
