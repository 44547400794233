import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { pick } from 'lodash';
import { BasePage, PendingActions, SubjectTrialStatusChip } from '@components';
import {
  AddSubjectInput,
  AddressValidationStatus,
  DraftFillingStatus,
  GetSiteTrialsFromSiteStaffUserDocument,
  useDeleteSubjectDraftMutation,
  useGetCustomFieldsValidationQuery,
  useGetProfileQuery,
  useGetSiteTrialQuery,
  useGetSubjectDraftQuery,
} from '@fdha/graphql-api-sitestaff';
import {
  Button,
  getCustomFieldsName,
  shouldShowCustomField,
  useSnackbar,
} from '@fdha/web-ui-library';
import { Stack, Paper } from '@mui/material';
import { parseBackendError } from '@utils';
import { useSaveSubject } from '@hooks';

import { ProfileInformation } from './ProfileInformation';

export const DraftProfile = () => {
  const { showSnackbarV2 } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const { showConfirmationDialog } = useSaveSubject();

  const { profileId, trialId = '' } = params;

  const [deleteDraft] = useDeleteSubjectDraftMutation({
    refetchQueries: [GetSiteTrialsFromSiteStaffUserDocument],
  });

  const { data, loading } = useGetSubjectDraftQuery({
    variables: { id: profileId || '' },
  });

  const { data: customFieldsData, loading: loadingCustomFields } =
    useGetCustomFieldsValidationQuery({
      variables: { trialId },
    });
  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();

  const { data: siteTrialData, loading: loadingSiteTrial } =
    useGetSiteTrialQuery({
      variables: { trialId: trialId },
    });

  const site = profileData?.me.site;
  const subjectDraft = data?.subjectDraft;
  const isLoading =
    loading || loadingProfile || loadingCustomFields || loadingSiteTrial;
  const customFields = customFieldsData?.customFieldsValidation;

  const supportedLanguages = useMemo(() => {
    return (
      profileData?.me?.site.siteTrials?.find((st) => st.trial?.id === trialId)
        ?.languages || []
    );
  }, [profileData, trialId]);

  const isDraftFillingComplete =
    subjectDraft?.fillingStatus === DraftFillingStatus.Complete;

  const showTreatmentStartDate = shouldShowCustomField(
    'treatmentStartDate',
    customFields,
    isLoading,
    true
  );

  const commonFields = useMemo(
    () => ({
      firstName: subjectDraft?.firstName,
      lastName: subjectDraft?.lastName,
      subjectId: subjectDraft?.subjectId,
      birthdate: subjectDraft?.birthdate,
      weight: subjectDraft?.weight,
      height: subjectDraft?.height,
      gender: subjectDraft?.gender,
      physicalActivityLevel: subjectDraft?.physicalActivityLevel,
      address: subjectDraft?.address,
      email: subjectDraft?.email,
      emailConfirmation: subjectDraft?.emailConfirmation,
      phoneNumber: subjectDraft?.phoneNumber,
      caregiver: subjectDraft?.caregiver,
      language: subjectDraft?.language,
    }),
    [subjectDraft]
  );

  const allCustomFields = useMemo(
    () => ({
      treatmentStartDate: subjectDraft?.treatmentStartDate,
    }),
    [subjectDraft]
  );

  const allFields = useMemo(() => {
    const customFieldsOfTrial = getCustomFieldsName(false, customFields);

    return {
      ...commonFields,
      ...pick(allCustomFields, customFieldsOfTrial),
    };
  }, [allCustomFields, commonFields, customFields]);

  const hasMissingData = useMemo(() => {
    const requiredCustomFields = getCustomFieldsName(true, customFields);

    const allRequiredFields = {
      ...commonFields,
      ...pick(allCustomFields, requiredCustomFields),
    };

    return Object.entries(allRequiredFields).some(([key, value]) => {
      if (key === 'address') {
        return !value?.formatted;
      } else if (key === 'caregiver') {
        return (
          value?.hasCaregiver == null ||
          (value.hasCaregiver &&
            (!value.name || !value.primary_caregiver_email))
        );
      }
      return value == null;
    });
  }, [allCustomFields, commonFields, customFields]);

  const handleSubmit = () => {
    const payload = getPayload();
    showConfirmationDialog(
      payload,
      !!siteTrialData?.siteTrial.onboardingCallNeeded
    );
  };

  const handleEdit = () => {
    navigate('./edit-draft');
  };

  const getPayload = (): AddSubjectInput => {
    if (
      !subjectDraft?.id ||
      !allFields.firstName ||
      !allFields.lastName ||
      !allFields.email ||
      !allFields.emailConfirmation ||
      !allFields.subjectId ||
      !trialId ||
      !site?.id ||
      !allFields.gender ||
      !allFields.birthdate ||
      !allFields.physicalActivityLevel ||
      !allFields.weight ||
      !allFields.height ||
      !allFields.address?.formatted ||
      !allFields.phoneNumber ||
      (!allFields.language && supportedLanguages.length > 1) ||
      allFields.caregiver?.hasCaregiver == null
    ) {
      throw new Error('Missing required data');
    }

    return {
      draftId: subjectDraft.id,
      name: `${allFields.firstName} ${allFields.lastName}`,
      email: allFields.email,
      trialId: trialId,
      siteId: site.id,
      subjectId: allFields.subjectId,
      gender: allFields.gender,
      birthdate: allFields.birthdate,
      physicalActivityLevel: allFields.physicalActivityLevel,
      weight: allFields.weight,
      height: allFields.height,
      address: {
        formatted: allFields.address.formatted,
        country: allFields.address.country || '',
        complement: allFields.address.complement,
        region: allFields.address.region || '',
        locality: allFields.address.locality || '',
        streetAddress: allFields.address.street_address || '',
        postalCode: allFields.address.postal_code || '',
      },
      phoneNumber: allFields.phoneNumber,
      caregiver: {
        name: allFields.caregiver?.name,
        primary_caregiver_email: allFields.caregiver?.primary_caregiver_email,
      },
      questions: subjectDraft?.questions,
      addressValidationStatus:
        AddressValidationStatus[
          subjectDraft.address
            ?.addressValidationStatus as unknown as keyof typeof AddressValidationStatus
        ],
      treatmentStartDate: allFields.treatmentStartDate,
      language: allFields.language || supportedLanguages[0],
    };
  };

  const handleDelete = async () => {
    try {
      await deleteDraft({ variables: { draftId: profileId || '' } });

      navigate('../');
      showSnackbarV2({
        message: 'Draft has been deleted.',
        severity: 'success',
      });
    } catch (error) {
      const message = parseBackendError(error, 'Error deleting draft');

      showSnackbarV2({
        message,
        severity: 'error',
      });
    }
  };

  const title = `${data?.subjectDraft?.firstName} ${data?.subjectDraft?.lastName}`;

  return (
    <BasePage
      type="default"
      isLoading={isLoading}
      headerProps={{ title, subtitle: subjectDraft?.subjectId || '' }}
      navigationProps={{ type: 'breadcrumb' }}
    >
      <Stack spacing={2}>
        <PendingActions
          mode="box"
          pendingActionItems={[
            {
              type: isDraftFillingComplete
                ? 'PendingProfileSubmission'
                : 'PendingProfileFilling',
              onClick: isDraftFillingComplete ? handleSubmit : handleEdit,
            },
          ]}
          showSkeleton={isLoading}
        />
        <SubjectTrialStatusChip
          subjectStatus={subjectDraft?.subjectTrialStatus}
          showSkeleton={isLoading}
          sx={{ width: '100%' }}
          size="medium"
        />
        <ProfileInformation
          isDraft
          isLoading={isLoading}
          trialAbbreviation={subjectDraft?.trial.protocol_abbreviation}
          firstName={allFields.firstName}
          lastName={allFields.lastName}
          subjectId={allFields.subjectId}
          birthdate={allFields.birthdate}
          weight={allFields.weight}
          height={allFields.height}
          gender={allFields.gender}
          physicalActivityLevel={allFields.physicalActivityLevel}
          address={allFields.address}
          email={allFields.email}
          emailConfirmation={allFields.emailConfirmation}
          phoneNumber={allFields.phoneNumber}
          caregiver={allFields.caregiver}
          treatmentStartDate={allFields.treatmentStartDate}
          showTreatmentStartDate={showTreatmentStartDate}
          language={allFields.language}
        />
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: 4,
            py: 3,
          }}
        >
          <Button
            color="error"
            variant="outlined"
            onClick={handleDelete}
            data-testid="DELETE_DRAFT_BUTTON"
            startEvaIcon={{
              name: 'trash-outline',
            }}
          >
            Delete draft
          </Button>
          <Stack spacing={2} direction="row">
            <Button
              data-testid="EDIT_DRAFT_BUTTON"
              variant="outlined"
              onClick={handleEdit}
              startEvaIcon={{ name: 'edit-outline' }}
            >
              Edit draft
            </Button>
            <Button
              variant="contained"
              disabled={hasMissingData || isLoading}
              onClick={handleSubmit}
              endEvaIcon={{ name: 'arrow-forward-outline' }}
              data-testid="DRAFT_SUBMIT_PROFILE"
            >
              Submit profile
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </BasePage>
  );
};
