import { Box, Card, CardActionArea, useTheme } from '@mui/material';
import React, { FC } from 'react';

import { formatUTCDate } from '../../utils';
import { Typography } from '../Typography/Typography';
import { IconMenu } from '../Menu/IconMenu';

import { DeliveryStatus } from './DeliveryStatus';

export interface DeliveryCardProps {
  buttonLabel: string;
  deliveryId: string;
  deliveryDate: string;
  openChanges: string;
  status: 'open' | 'closed';
  isCanceled: boolean;
  showIconMenu?: boolean;
  weekOfCycle?: string;
  onClick: () => void;
}

type IconMenuProps = {
  showIconMenu: true;
  onEdit: (id: string) => void;
  onCancel: (id: string) => void;
  onReactivate: (id: string) => void;
};

type IconMenuPropsFalse = {
  showIconMenu?: false;
};

type DeliveryCardPropsWithIconMenu = DeliveryCardProps &
  (IconMenuProps | IconMenuPropsFalse);

const DeliveryCard: FC<DeliveryCardPropsWithIconMenu> = ({
  buttonLabel,
  deliveryId,
  deliveryDate,
  openChanges,
  status,
  isCanceled,
  weekOfCycle,
  onClick,
  ...props
}) => {
  const theme = useTheme();

  const { showIconMenu } = props;

  const editMenuOption = {
    label: 'Edit order',
    testId: 'EDIT_MENU_OPTION',
    icon: 'edit-outline',
    disabled: status === 'closed' || isCanceled ? true : false,
    handleClick: () => showIconMenu && props.onEdit(deliveryId),
  };
  const cancelMenuOption = {
    label: 'Cancel delivery',
    testId: 'CANCEL_MENU_OPTION',
    icon: 'alert-circle-outline',
    handleClick: () => showIconMenu && props.onCancel(deliveryId),
  };
  const reactivateMenuOption = {
    label: 'Reactivate delivery',
    testId: 'REACTIVATE_MENU_OPTION',
    icon: 'alert-circle-outline',
    handleClick: () => showIconMenu && props.onReactivate(deliveryId),
  };

  return (
    <Card
      data-testid={`DELIVERY_CARD_${deliveryId}`}
      elevation={5}
      sx={{ boxShadow: theme.shadows[2], mb: 2 }}
    >
      <Box
        padding={1.5}
        display="flex"
        justifyContent="space-between"
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Box>
          <Typography
            variant="body1"
            color={theme.palette.text.secondary}
            fontSize={10}
            fontWeight={500}
          >
            Delivery Date
          </Typography>
          <Box display="flex" paddingY={1}>
            <Typography fontWeight={500}>
              {formatUTCDate(deliveryDate, 'monthWeekDay')}
            </Typography>
            {weekOfCycle && (
              <Typography
                variant="overline"
                color={theme.palette.text.hint}
                ml={1}
                lineHeight={2}
              >
                {`WEEK ${weekOfCycle}`}
              </Typography>
            )}
          </Box>
          <DeliveryStatus
            openChangesDate={openChanges}
            status={status}
            isCanceled={isCanceled}
          />
        </Box>
        {showIconMenu && (
          <IconMenu
            items={[
              editMenuOption,
              isCanceled === false ? cancelMenuOption : reactivateMenuOption,
            ]}
          />
        )}
      </Box>
      <CardActionArea onClick={onClick} sx={{ padding: 1 }}>
        <Typography variant="body2" color={theme.palette.info.main}>
          {buttonLabel}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default DeliveryCard;
