import React, { FC, useState } from 'react';
import { Form, Formik, FormikState } from 'formik';
import { Stack, Link, Box } from '@mui/material';
import {
  Button,
  PasswordTextField,
  theme,
  useSnackbar,
  Typography,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslatedErrorMessages } from '@fdha/common-hooks';

import { PasswordRule, PasswordRuleUtils } from '../utils/passwordRuleUtils';

interface ProfileChangePasswordErrorsProps {
  currentPasswordError: string;
  passwordTipsError: string;
  newPasswordError: string;
  confirmPasswordError: string;
}

export interface ProfileChangePasswordValuesProps {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface ProfileChangePasswordFormProps {
  hint: string;
  hintV2?: PasswordRule[];
  errors: ProfileChangePasswordErrorsProps;
  handleForgotPassword: () => void;
  handleSubmit: (
    values: ProfileChangePasswordValuesProps,
    resetForm: (
      nextState?:
        | Partial<FormikState<ProfileChangePasswordValuesProps>>
        | undefined
    ) => void
  ) => void;
  isLinkDisabled: boolean;
  v2?: boolean;
}

const ProfileChangePasswordForm: FC<ProfileChangePasswordFormProps> = ({
  hint,
  hintV2,
  errors,
  handleSubmit,
  handleForgotPassword,
  isLinkDisabled,
  v2,
}) => {
  const navigate = useNavigate();
  const { matchPasswordMessage, requiredMessage } =
    useTranslatedErrorMessages();

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const schema = Yup.object().shape({
    currentPassword: Yup.string().required(requiredMessage),
    newPassword: Yup.string().required(requiredMessage),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], matchPasswordMessage)
      .required(requiredMessage),
  });

  const [rulesErrorList, setRulesErrorList] = useState<string[] | undefined>(
    undefined
  );

  const { showSnackbarV2 } = useSnackbar();

  /************ V2 updates *********************/

  const titleNewPassword = v2 ? 'New Password' : 'New password';
  const titleConfirmNewPassword = v2
    ? 'Confirm New Password'
    : 'Confirm new password';
  const titleCurrentPassword = v2 ? 'Current Password' : 'Current password';
  const placeholderNewPassword = v2 ? '' : 'New password...';
  const placeholderConfirmNewPassword = v2 ? '' : 'Confirm new password...';
  const placeholderCurrentPassword = v2 ? '' : 'Current password...';
  const forgotPassword = v2 ? 'Forgot password?' : 'Forgot your password?';
  const newPasswordErrorMessage = v2
    ? undefined
    : errors.passwordTipsError || errors.newPasswordError;

  const validationSchema = v2 ? schema : null;

  const baseStyle = v2
    ? {
        width: 'fit-content',
        marginTop: '4px',
      }
    : { marginBottom: '24px', width: 'fit-content' };

  const linkVariant = v2 ? 'body1' : 'inherit';

  /***********************************/

  const getLinkStyle = () => {
    const additionalStyle = isLinkDisabled
      ? {
          textDecoration: 'none',
          color: theme.palette.text.primary,
          cursor: 'default',
        }
      : {};

    return {
      ...baseStyle,
      ...additionalStyle,
    };
  };

  const handleKeyPress = (
    event: React.KeyboardEvent,
    values: ProfileChangePasswordValuesProps,
    resetForm: (
      nextState?:
        | Partial<FormikState<ProfileChangePasswordValuesProps>>
        | undefined
    ) => void
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(values, resetForm);
    }
  };

  const handleValidate = (values: ProfileChangePasswordValuesProps) => {
    const errors: Partial<ProfileChangePasswordValuesProps> = {};

    if (values.newPassword && v2) {
      setRulesErrorList(
        PasswordRuleUtils.getPasswordRulesListErrorV2(values.newPassword.trim())
      );
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      validate={handleValidate}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        isSubmitting,
        resetForm,
        touched,
        handleBlur,
        errors: formErrors,
      }) => {
        const showRulesError = touched.newPassword && !!rulesErrorList?.length;
        const disabled = v2
          ? isSubmitting
          : !values.newPassword ||
            !values.confirmNewPassword ||
            !values.currentPassword ||
            isSubmitting;

        const error = {
          currentPassword:
            (touched.currentPassword && formErrors.currentPassword) ||
            errors.currentPasswordError,
          newPassword: v2
            ? touched.newPassword && formErrors.newPassword
            : newPasswordErrorMessage,
          confirmNewPassword: v2
            ? touched.confirmNewPassword && formErrors.confirmNewPassword
            : errors.confirmPasswordError,
        };

        const newPasswordHint = v2 ? error.newPassword?.toString() : hint;

        return (
          <Form
            style={{ width: '100%' }}
            onKeyPress={(event: React.KeyboardEvent) =>
              handleKeyPress(event, values, resetForm)
            }
          >
            <Stack spacing={2}>
              <PasswordTextField
                v2={v2}
                name="currentPassword"
                title={titleCurrentPassword}
                placeholder={placeholderCurrentPassword}
                value={values.currentPassword}
                onChange={handleChange}
                error={!!error.currentPassword}
                helperText={error.currentPassword}
                errorMessage={errors.currentPasswordError}
                onBlur={handleBlur}
                i18nKeyTitle="profile:changePassword.input.currentPassword.label"
              />
              <Link
                data-testid="FORGOT_PASSWORD_LINK"
                component="button"
                style={getLinkStyle()}
                onClick={(event) => {
                  event.preventDefault();
                  handleForgotPassword();
                }}
                disabled={isLinkDisabled}
                onKeyPress={(event: React.KeyboardEvent) =>
                  event.stopPropagation()
                }
                variant={linkVariant}
              >
                <Typography
                  i18nKey={
                    isLinkDisabled
                      ? 'profile:changePassword.forgotPassword.timeout'
                      : 'profile:changePassword.forgotPassword.label'
                  }
                >
                  {isLinkDisabled
                    ? 'Wait 15 seconds before requesting again...'
                    : `${forgotPassword}`}
                </Typography>
              </Link>
              <PasswordTextField
                v2={v2}
                name="newPassword"
                title={titleNewPassword}
                placeholder={placeholderNewPassword}
                value={values.newPassword}
                onChange={handleChange}
                hint={newPasswordHint}
                passwordHint={hintV2}
                rulesErrorList={rulesErrorList}
                showRulesError={showRulesError}
                error={!!error.newPassword}
                errorMessage={newPasswordErrorMessage}
                onBlur={handleBlur}
                i18nKeyTitle="profile:changePassword.input.newPassword.label"
              />
              <PasswordTextField
                v2={v2}
                name="confirmNewPassword"
                title={titleConfirmNewPassword}
                placeholder={placeholderConfirmNewPassword}
                value={values.confirmNewPassword}
                onChange={handleChange}
                error={!!error.confirmNewPassword}
                helperText={error.confirmNewPassword}
                errorMessage={errors.confirmPasswordError}
                onBlur={handleBlur}
                i18nKeyTitle="profile:changePassword.input.confirmNewPassword.label"
              />
            </Stack>
            {v2 ? (
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  sx={{ mr: 4 }}
                  onClick={() => {
                    showSnackbarV2({
                      message: 'Changes Not Saved',
                      severity: 'info',
                      i18nKey: 'common:snackbar.changesNotSaved',
                    });
                    navigate('../', { replace: true });
                  }}
                  data-testid="CANCEL_PASSWORD_BUTTON"
                  i18nKey="common:button:cancel"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={disabled}
                  sx={{ width: '160px' }}
                  data-testid="UPDATE_PASSWORD_BUTTON"
                  i18nKey="common:button:update"
                >
                  Update
                </Button>
              </Box>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={disabled}
                sx={{ width: '100%', height: '42px', marginTop: 3 }}
              >
                Update
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfileChangePasswordForm;
