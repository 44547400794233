"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRequestDateValid = exports.getRequestDate = exports.getEarliestMFSD = exports.convertKitchenTimeToUTC = exports.convertDateToKitchenTime = void 0;
var date_fns_1 = require("date-fns");
var date_fns_tz_1 = require("date-fns-tz");
var date_1 = require("../date/date");
var KITCHEN_TIMEZONE = 'America/Denver'; // It can be MST (-7) or MDT (-6)
var getEarliestDeliveryDate = function (date) {
    var day = date.getDay();
    switch (day) {
        // Faeth does not have deliveries from Saturday to Monday
        case date_1.WeekDay.Sat:
        case date_1.WeekDay.Sun:
        case date_1.WeekDay.Mon:
            return (0, date_fns_1.previousFriday)(date);
        default:
            return date;
    }
};
var getPriorNumberOfDaysByDeliveryWeekDay = function (deliveryWeekDay) {
    return 1;
};
/**
 *  Convert a date to kitchen time
 *  @param date Date to convert
 *  @returns Date in kitchen time
 */
var convertDateToKitchenTime = function (date) {
    return (0, date_fns_tz_1.utcToZonedTime)(date, KITCHEN_TIMEZONE);
};
exports.convertDateToKitchenTime = convertDateToKitchenTime;
/**
 *  Convert a kitchen time to UTC
 *  @param date Date to convert
 *  @returns Date in UTC
 */
var convertKitchenTimeToUTC = function (date) {
    return (0, date_fns_tz_1.zonedTimeToUtc)(date, KITCHEN_TIMEZONE);
};
exports.convertKitchenTimeToUTC = convertKitchenTimeToUTC;
/**
 * Returns the earliest medical food start date (MFSD) based on the given reference date
 * @param refDate Date to calculate the earliest MFSD
 * @returns The earliest MFSD
 */
var getEarliestMFSD = function (refDate) {
    var refDateAdjusted = refDate;
    if (refDate < new Date()) {
        refDateAdjusted = (0, date_fns_tz_1.zonedTimeToUtc)((0, date_fns_1.startOfToday)(), 'UTC');
    }
    var requestDate = (0, exports.getRequestDate)(refDateAdjusted);
    if ((0, exports.isRequestDateValid)(requestDate)) {
        return refDateAdjusted;
    }
    else {
        var nextRefDate = (0, date_fns_1.addDays)(refDateAdjusted, 1);
        return (0, exports.getEarliestMFSD)(nextRefDate);
    }
};
exports.getEarliestMFSD = getEarliestMFSD;
/**
 * Returns the request date based on the medical food start date
 * @param medicalFoodStartDateUtc Date in UTC
 * @returns Request date in UTC
 */
var getRequestDate = function (medicalFoodStartDateUtc) {
    var medicalFoodStartDateLocalUtc = (0, date_1.convertDateToLocalUtc)(medicalFoodStartDateUtc);
    // Frozen Food should be delivery 1 day before the medical food start date
    var deliveryDate = (0, date_fns_1.subDays)(medicalFoodStartDateLocalUtc, 1);
    var earliestDeliveryDate = getEarliestDeliveryDate(deliveryDate);
    var priorNumberOfDays = getPriorNumberOfDaysByDeliveryWeekDay(earliestDeliveryDate.getDay());
    var requestDate = (0, date_fns_1.subDays)(earliestDeliveryDate, priorNumberOfDays);
    requestDate.setHours(8, 0, 0, 0);
    var requestDateKitchenTimeUTC = (0, exports.convertKitchenTimeToUTC)(requestDate);
    return requestDateKitchenTimeUTC;
};
exports.getRequestDate = getRequestDate;
/**
 * Check if the request date is valid
 * @param requestDateUtc Request date in UTC
 * @returns True if the request date is valid, otherwise false
 */
var isRequestDateValid = function (requestDateUtc) {
    var currentKitchenTime = (0, exports.convertDateToKitchenTime)(new Date());
    var requestDateKitchenTime = (0, exports.convertDateToKitchenTime)(requestDateUtc);
    return requestDateKitchenTime > currentKitchenTime;
};
exports.isRequestDateValid = isRequestDateValid;
