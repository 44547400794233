import React, { useMemo, useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  Paper as MuiPaper,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import {
  Button,
  DecimalTextFieldV2,
  MaskedTextFieldV2,
  SelectV2,
  TextFieldV2,
  convertMaskToE164,
  useLoadingBar,
  useSnackbar,
  DatePickerV2,
  Typography,
  AddressFieldsV2,
  ConditionalWrapper,
  shouldShowCustomField,
  CustomText,
  SelectOption,
} from '@fdha/web-ui-library';
import {
  AddSubjectInput,
  CustomFieldName,
  DateValidation,
  GetSiteTrialsFromSiteStaffUserDocument,
  PhysicalActivityLevel,
  SaveSubjectDraftInput,
  SubjectDraft,
  UserGender,
  useEditSubjectDraftMutation,
  useGetCustomFieldsValidationQuery,
  useGetProfileQuery,
  useGetSiteTrialQuery,
  useGetSupportedLanguagesQuery,
  useSaveSubjectDraftMutation,
} from '@fdha/graphql-api-sitestaff';
import { FormikErrors, useFormik, yupToFormErrors } from 'formik';
import {
  parseBackendError,
  getFormikError,
  getValidationSchema,
  SubjectSchema,
  genderOptions,
  physicalActivityOptions,
  getInitialValues,
  languageInfoText,
} from '@utils';
import { isBefore, isPast, startOfDay } from 'date-fns';
import { useSaveSubject } from '@hooks';
import { useNavigate } from 'react-router';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
  rowGap: theme.spacing(3),
}));

interface AddOrEditSubjectProps {
  trialId: string;
  loading?: boolean;
  subject?: SubjectDraft;
  onCancel?: () => void;
}

export const AddOrEditSubject: React.FC<AddOrEditSubjectProps> = ({
  trialId,
  loading,
  subject,
  onCancel,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showLoadingV2, hideLoading } = useLoadingBar();
  const { showSnackbarV2 } = useSnackbar();
  const { showConfirmationDialog, handleSuccess } = useSaveSubject();

  const [isDraft, setIsDraft] = useState(false);
  const [dateView, setDateView] = useState('day');

  const [addDraft] = useSaveSubjectDraftMutation({
    refetchQueries: [GetSiteTrialsFromSiteStaffUserDocument],
  });
  const [editDraft] = useEditSubjectDraftMutation();

  const { data: siteTrialData, loading: loadingTrial } = useGetSiteTrialQuery({
    variables: { trialId },
    skip: !trialId,
  });
  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();
  const { data: customFieldsData, loading: loadingCustomFields } =
    useGetCustomFieldsValidationQuery({
      variables: { trialId },
    });

  const { data: languagesData } = useGetSupportedLanguagesQuery();
  const allSupportedLanguages = languagesData?.supportedLanguages || [];

  const trial = siteTrialData?.siteTrial.trial;
  const isNeaarTrial = trial?.protocol_abbreviation === 'N001';
  const isLarcTrial = trial?.protocol_abbreviation === 'LARC';
  const me = profileData?.me;
  const isEditing = !!subject?.id;
  const customFields = customFieldsData?.customFieldsValidation;
  const siteIdentification = me?.site.siteTrials?.find(
    (st) => st.trial?.id === trial?.id
  )?.site_identification;

  const supportedLanguages = me?.site.siteTrials
    ?.find((st) => st.trial?.id === trial?.id)
    ?.languages?.map((l) => {
      return {
        value: l,
        label: allSupportedLanguages.find((lang) => lang.code === l)
          ?.nameEnglish,
      };
    }) as SelectOption[];

  const showLanguageOption =
    supportedLanguages && supportedLanguages.length > 1;

  const subjectIdPrefix = useMemo(() => {
    return `${trial?.protocol_abbreviation}-${siteIdentification}-`;
  }, [trial?.protocol_abbreviation, siteIdentification]);

  const getPayload = () => {
    if (!values.birthdate && !isDraft) {
      throw new Error('Birth date is required');
    }

    return {
      email: values.email,
      subjectId: values.subjectId
        ? `${subjectIdPrefix}${values.subjectId}`
        : '',
      gender: UserGender[values.gender as unknown as keyof typeof UserGender],
      birthdate: values.birthdate
        ? startOfDay(values.birthdate).toISOString()
        : null,
      physicalActivityLevel:
        PhysicalActivityLevel[
          values.physicalActivityLevel as unknown as keyof typeof PhysicalActivityLevel
        ],
      weight: Number(values.weight),
      height: Number(values.height),
      address: {
        formatted: values.address.formatted,
        country: values.address.country,
        complement: values.complement,
        region: values.address.region,
        locality: values.address.locality,
      },
      phoneNumber: convertMaskToE164(values.phoneNumber),
      caregiver: {
        name: values.caregiverName,
        primary_caregiver_email: values.caregiverEmail,
      },
      questions: values.questions,
      treatmentStartDate: values.treatmentStartDate
        ? startOfDay(values.treatmentStartDate).toISOString()
        : null,
    };
  };

  const handleSaveSubject = () => {
    const payload = getPayload();
    const input: AddSubjectInput = {
      ...payload,
      name: `${values.firstName} ${values.lastName}`,
      trialId,
      siteId: me?.site.id || '',
      draftId: subject?.id || '',
      addressValidationStatus: values.addressValidationStatus,
      language: values.language || String(supportedLanguages[0]?.value),
      address: {
        ...payload.address,
        streetAddress: values.address.streetAddress,
        postalCode: values.address.postalCode,
      },
    };

    showConfirmationDialog(
      input,
      !!siteTrialData?.siteTrial.onboardingCallNeeded
    );
  };

  const handleSaveDraft = async () => {
    showLoadingV2();

    const payload = getPayload();

    const draftPayload = {
      ...payload,
      firstName: values.firstName,
      lastName: values.lastName,
      emailConfirmation: values.confirmEmail,
      language: showLanguageOption
        ? values.language || null
        : String(supportedLanguages[0]?.value),
      caregiver: {
        ...payload.caregiver,
        hasCaregiver: !!values.caregiverName && !!values.caregiverEmail,
      },
      address: {
        ...payload.address,
        street_address: values.address.streetAddress,
        postal_code: values.address.postalCode,
        addressValidationStatus: values.addressValidationStatus,
      },
    };

    try {
      if (isEditing) {
        await editDraft({
          variables: { draftId: subject?.id || '', input: draftPayload },
        });
        navigate('../');
      } else {
        const input: SaveSubjectDraftInput = {
          ...draftPayload,
          trialId,
          siteId: me?.site.id || '',
        };
        const result = await addDraft({ variables: { input } });
        handleSuccess(result.data?.saveSubjectDraft.id);
      }

      showSnackbarV2({
        message: 'Draft has been saved.',
        severity: 'success',
      });
    } catch (error) {
      const message = parseBackendError(error, 'Error saving draft');

      showSnackbarV2({
        message,
        severity: 'error',
      });
    } finally {
      hideLoading();
    }
  };

  const handleCancel = () => {
    showSnackbarV2({ message: 'Changes not saved', severity: 'info' });
    onCancel?.();
  };

  const onSubmit = async () => {
    try {
      if (!trial?.protocol_abbreviation) {
        throw new Error('Protocol abbreviation is required');
      }

      await getValidationSchema(
        isDraft,
        showLanguageOption,
        customFields
      ).validate(values, {
        abortEarly: false,
      });

      isDraft ? handleSaveDraft() : handleSaveSubject();
    } catch (error) {
      const errors: FormikErrors<{ key: string }> = yupToFormErrors(error);
      Object.entries(errors).map(([key, value]) => setFieldError(key, value));
      setSubmitting(false);
    }
  };

  const handleChange = (key: string, value: any) => {
    setFieldValue(key, value);
    setFieldError(key, undefined);
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setSubmitting,
  } = useFormik({
    initialValues: getInitialValues(subject),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  });

  const getError = (name: string) => {
    return getFormikError<SubjectSchema>(name, errors, touched);
  };

  const isLoading =
    loadingCustomFields || loadingProfile || loadingTrial || loading;

  const showTreatmentStartDate = shouldShowCustomField(
    CustomFieldName.TreatmentStartDate,
    customFields,
    isLoading,
    isDraft
  );

  const treatmentMinDate = (
    customFields?.find((f) => f.name === CustomFieldName.TreatmentStartDate)
      ?.draftValidation as DateValidation
  )?.min;

  const treatmentStartDateText = useMemo(
    () =>
      dateView === 'day'
        ? 'Some dates are unavailable for treatment start date to allow for patient onboarding.'
        : '',
    [dateView]
  );

  const shouldDisableDateHandler = isNeaarTrial
    ? (date: Date) =>
        isBefore(startOfDay(date!), startOfDay(new Date(treatmentMinDate)))
    : undefined;

  const actionBarContent = () =>
    isNeaarTrial ? (
      <CustomText
        data-testid="ACTION_BAR_CONTENT_TEXT"
        text={treatmentStartDateText}
      />
    ) : null;

  return (
    <Stack spacing={1} data-testid="ADD_EDIT_SUBJECT_FORM">
      <Paper>
        <Typography variant="h4" showSkeleton={isLoading}>
          Patient information
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={4} xs={6}>
              <TextFieldV2
                title="First name"
                name="firstName"
                onChange={(e) => handleChange('firstName', e.target.value)}
                error={!!getError('firstName')}
                helperText={getError('firstName')}
                placeholder="Insert first name"
                value={values.firstName}
                showSkeleton={isLoading}
                required
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <TextFieldV2
                title="Last name"
                name="lastName"
                onChange={(e) => handleChange('lastName', e.target.value)}
                error={!!getError('lastName')}
                helperText={getError('lastName')}
                placeholder="Insert last name"
                value={values.lastName}
                showSkeleton={isLoading}
                required
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <MaskedTextFieldV2
                mask="number"
                maxLength={3}
                placeholder="000"
                title="Subject ID number*"
                name="subjectId"
                onChange={(e) => handleChange('subjectId', e.target.value)}
                error={!!getError('subjectId')}
                helperText={getError('subjectId')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {subjectIdPrefix}
                    </InputAdornment>
                  ),
                }}
                value={values.subjectId}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <DatePickerV2
                title="Birth date*"
                onChange={(value) => handleChange('birthdate', value)}
                value={values.birthdate || null}
                error={!!getError('birthdate')}
                helperText={getError('birthdate')}
                shouldDisableDate={(date) => !isPast(startOfDay(date!))}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <DecimalTextFieldV2
                title="Weight (in kg)*"
                placeholder="00.0 kg"
                name="weight"
                numberOfDecimals={1}
                maxLength={5}
                handleChange={(value) => handleChange('weight', value)}
                error={!!getError('weight')}
                helperText={getError('weight')}
                value={values.weight}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <DecimalTextFieldV2
                title="Height (in cm)*"
                placeholder="00.0 cm"
                name="height"
                numberOfDecimals={1}
                maxLength={5}
                handleChange={(value) => handleChange('height', value)}
                error={!!getError('height')}
                helperText={getError('height')}
                value={values.height}
                showSkeleton={isLoading}
              />
            </Grid>
            <ConditionalWrapper
              showWrapper={!showTreatmentStartDate}
              wrapper={(children) => (
                <Grid item lg={4} md={6} xs={12}>
                  {children}
                </Grid>
              )}
            >
              <ConditionalWrapper
                showWrapper={showTreatmentStartDate}
                wrapper={(children) => (
                  <Grid item lg={4} xs={6}>
                    {children}
                  </Grid>
                )}
              >
                <SelectV2
                  title="Assigned sex at birth*"
                  options={genderOptions}
                  placeholder="Choose sex"
                  displayEmpty
                  name="gender"
                  value={values.gender}
                  onChange={(e) =>
                    handleChange('gender', e.target.value as string)
                  }
                  error={!!getError('gender')}
                  helperText={getError('gender')}
                  showSkeleton={isLoading}
                />
              </ConditionalWrapper>
            </ConditionalWrapper>
            <ConditionalWrapper
              showWrapper={!showTreatmentStartDate}
              wrapper={(children) => (
                <Grid item lg={8} md={6} xs={12}>
                  {children}
                </Grid>
              )}
            >
              <ConditionalWrapper
                showWrapper={showTreatmentStartDate}
                wrapper={(children) => (
                  <Grid item lg={4} xs={6}>
                    {children}
                  </Grid>
                )}
              >
                <SelectV2
                  title="Physical activity level*"
                  options={physicalActivityOptions}
                  name="physicalActivityLevel"
                  value={values.physicalActivityLevel}
                  onChange={(e) =>
                    handleChange(
                      'physicalActivityLevel',
                      e.target.value as string
                    )
                  }
                  error={!!getError('physicalActivityLevel')}
                  helperText={getError('physicalActivityLevel')}
                  placeholder="Choose an option"
                  displayEmpty
                  showSkeleton={isLoading}
                />
              </ConditionalWrapper>
            </ConditionalWrapper>
            {showTreatmentStartDate && (
              <Grid item lg={4} xs={6}>
                <DatePickerV2
                  title={isLarcTrial ? 'Radiotherapy start date' : 'C1D1 date'}
                  required
                  value={values.treatmentStartDate}
                  showSkeleton={isLoading}
                  error={!!getError('treatmentStartDate')}
                  helperText={getError('treatmentStartDate')}
                  onChange={(value) =>
                    handleChange('treatmentStartDate', value)
                  }
                  shouldDisablePastDates={isNeaarTrial}
                  shouldDisableDate={shouldDisableDateHandler}
                  onViewChange={(view) => setDateView(view)}
                  slots={{
                    actionBar: actionBarContent,
                  }}
                />
              </Grid>
            )}
          </Grid>
          {showLanguageOption && (
            <Box data-testid="PRIMARY_LANGUAGE" mt={4}>
              <Typography variant="h5" showSkeleton={isLoading}>
                Primary language
              </Typography>
              <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                display="block"
                mt={1}
                mb={2}
                mr={1}
                showSkeleton={isLoading}
              >
                {languageInfoText}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SelectV2
                    title="Primary language*"
                    options={supportedLanguages}
                    placeholder="Select an option"
                    displayEmpty
                    name="language"
                    value={values.language}
                    onChange={(e) =>
                      handleChange('language', e.target.value as string)
                    }
                    error={!!getError('language')}
                    helperText={getError('language')}
                    showSkeleton={isLoading}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>
      <Paper>
        <Box>
          <Typography variant="h4" showSkeleton={isLoading}>
            Contact information
          </Typography>
          <Typography
            variant="body1"
            color={theme.palette.text.secondary}
            display="block"
            mt={1}
            showSkeleton={isLoading}
          >
            We will use this information to send all trial-related deliveries.
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AddressFieldsV2
                isRequired
                showSkeleton={isLoading}
                address={values.address}
                complement={values.complement}
                addressValidationStatus={values.addressValidationStatus}
                onChangeAddress={(address) => {
                  handleChange('address', address);
                }}
                onChangeComplement={(complement) => {
                  handleChange('complement', complement);
                }}
                addressError={getError('address')}
                complementError={getError('complement')}
                onValidate={(value) => {
                  handleChange('addressValidationStatus', value);
                }}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <TextFieldV2
                title="Email address"
                name="email"
                onChange={(e) => handleChange('email', e.target.value)}
                error={!!getError('email')}
                helperText={getError('email')}
                placeholder="email@email.com"
                value={values.email}
                showSkeleton={isLoading}
                required
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <TextFieldV2
                title="Confirm email address"
                name="confirmEmail"
                onChange={(e) => handleChange('confirmEmail', e.target.value)}
                error={!!getError('confirmEmail')}
                helperText={getError('confirmEmail')}
                placeholder="email@email.com"
                value={values.confirmEmail}
                showSkeleton={isLoading}
                required
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <MaskedTextFieldV2
                title="Mobile phone number*"
                name="phoneNumber"
                onChange={(e) => handleChange('phoneNumber', e.target.value)}
                error={!!getError('phoneNumber')}
                helperText={getError('phoneNumber')}
                placeholder="000-000-0000"
                mask="phoneNumber"
                value={values.phoneNumber}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldV2
                title="Please insert any questions you may have for the Faeth Study Dietitian team here:"
                required={false}
                placeholder="Type your question"
                multiline
                rows={5}
                name="questions"
                onChange={(e) => handleChange('questions', e.target.value)}
                value={values.questions}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              alignItems="end"
              my={1}
            >
              <Typography variant="h5" showSkeleton={isLoading}>
                Secondary contact information
              </Typography>
              <Typography
                variant="h6"
                color={theme.palette.text.hint}
                showSkeleton={isLoading}
                sx={{ fontStyle: 'italic', ml: 1 }}
              >
                (optional)
              </Typography>
            </Grid>
            <Grid item lg={8} xs={6}>
              <TextFieldV2
                title="Contact name"
                name="caregiverName"
                onChange={(e) => handleChange('caregiverName', e.target.value)}
                error={!!getError('caregiverName')}
                helperText={getError('caregiverName')}
                placeholder="Insert full name"
                value={values.caregiverName}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <TextFieldV2
                title="Contact email address"
                name="caregiverEmail"
                onChange={(e) => handleChange('caregiverEmail', e.target.value)}
                error={!!getError('caregiverEmail')}
                helperText={getError('caregiverEmail')}
                placeholder="email@email.com"
                value={values.caregiverEmail}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {!isLoading && (
        <MuiPaper
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: 3,
            px: 4,
          }}
        >
          <Button onClick={handleCancel}>Cancel</Button>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              onClick={() => {
                setIsDraft(true);
                handleSubmit();
              }}
              startEvaIcon={{ name: 'checkmark-outline' }}
              data-testid="SAVE_DRAFT_BUTTON"
            >
              Save draft
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsDraft(false);
                handleSubmit();
              }}
              endEvaIcon={{ name: 'arrow-forward-outline' }}
              data-testid="SUBMIT_PROFILE_BUTTON"
            >
              Submit profile
            </Button>
          </Stack>
        </MuiPaper>
      )}
    </Stack>
  );
};
