"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMissingOnboardingCall = exports.ACTIVE_SUBJECT_STATUS = void 0;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
exports.ACTIVE_SUBJECT_STATUS = [
    graphql_api_admin_1.SubjectStatus.Ongoing,
    graphql_api_admin_1.SubjectStatus.Screening,
];
/**
 * Returns true if the subject is missing an onboarding call.
 * @param status status of the subject
 * @param onboardingCallNeeded if the onboarding call is needed
 * @param onboardingCallScheduled if the onboarding call is scheduled
 * @returns true if onboarding call is needed and not scheduled
 */
var isMissingOnboardingCall = function (status, onboardingCallNeeded, onboardingCallScheduled) {
    return (!!status &&
        exports.ACTIVE_SUBJECT_STATUS.includes(status) &&
        onboardingCallNeeded === true &&
        onboardingCallScheduled === false);
};
exports.isMissingOnboardingCall = isMissingOnboardingCall;
