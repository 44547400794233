import React, { FC } from 'react';
import {
  GetPatientUserDocument,
  GetSiteTrialsFromSiteStaffUserDocument,
  useScheduleOnboardingCallMutation,
} from '@fdha/graphql-api-sitestaff';
import {
  Typography,
  Button,
  useSnackbar,
  useLoadingBar,
} from '@fdha/web-ui-library';
import { Paper, Stack } from '@mui/material';
import { parseBackendError } from '@utils';
import { OnboardingCallInfo } from '@components';
import { useNavigate } from 'react-router';

interface ConfirmOnboardingCallSchedulingProps {
  patientId: string;
  datetime: string;
  type: string;
  timezone: string;
  trialAbbreviation: string;
  callDuration: number;
  isLoading: boolean;
  setHasConfirmedTimeSelection: (value: boolean) => void;
  refetchTimes: () => void;
}

const timeSelectedError =
  'We could not find an available calendar at this time.';

export const ConfirmOnboardingCallScheduling: FC<ConfirmOnboardingCallSchedulingProps> =
  ({
    patientId,
    isLoading,
    datetime,
    type,
    callDuration,
    timezone,
    trialAbbreviation,
    setHasConfirmedTimeSelection,
    refetchTimes,
  }) => {
    const { showSnackbarV2 } = useSnackbar();
    const { showLoadingV2, hideLoading } = useLoadingBar();
    const [confirmAppointment] = useScheduleOnboardingCallMutation();

    const navigate = useNavigate();

    const handleConfirmAppointment = async () => {
      try {
        showLoadingV2();
        const payload = {
          datetime,
          timezone,
          patientId,
          trialAbbreviation,
        };
        await confirmAppointment({
          variables: {
            input: payload,
          },
          refetchQueries: [
            GetPatientUserDocument,
            GetSiteTrialsFromSiteStaffUserDocument,
          ],
        });

        showSnackbarV2({
          message: 'Onboarding is now scheduled.',
          severity: 'success',
        });

        navigate('../');
      } catch (error) {
        const message = parseBackendError(
          error,
          'Error when confirming onboarding call'
        );

        const messageText =
          message === timeSelectedError
            ? 'The selected time is no longer available.'
            : message;

        showSnackbarV2({
          message: messageText,
          severity: 'error',
        });

        setHasConfirmedTimeSelection(false);
        await refetchTimes();
      } finally {
        hideLoading();
      }
    };

    return (
      <Paper
        sx={{
          py: 3,
          px: 4,
        }}
      >
        <Stack spacing={4}>
          <Typography variant="h4" showSkeleton={isLoading}>
            Onboarding call scheduling
          </Typography>
          <OnboardingCallInfo
            isLoading={isLoading}
            datetime={datetime}
            type={type}
            callDuration={callDuration}
          />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            spacing={2}
          >
            <Button
              variant="outlined"
              onClick={() => setHasConfirmedTimeSelection(false)}
              showSkeleton={isLoading}
              startEvaIcon={{
                name: 'arrow-back-outline',
                size: 'large',
              }}
            >
              Back to scheduling
            </Button>
            <Button
              data-testid="CONFIRM_APPOINTMENT_BUTTON"
              variant="contained"
              onClick={handleConfirmAppointment}
              showSkeleton={isLoading}
              startEvaIcon={{
                name: 'arrow-circle-right-outline',
                size: 'large',
              }}
            >
              Confirm appointment
            </Button>
          </Stack>
        </Stack>
      </Paper>
    );
  };
