import React from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Box,
  useTheme,
  Stack,
} from '@mui/material';
import { useTranslation } from '@fdha/common-hooks';

import { InputError } from '../InputError/InputError';
import { InputHelperText } from '../InputHelperText/InputHelperText';
import { InputTitle } from '../InputTitle/InputTitle';
import { SkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper';
import { Typography } from '../Typography/Typography';

export type TextFieldProps = MuiTextFieldProps & {
  v2?: boolean;
  title?: string;
  subtitle?: string;
  endContent?: React.ReactNode;
  onlyNumbers?: boolean;
  required?: boolean;
  disabled?: boolean;
  showSkeleton?: boolean;
  i18nKeyTitle?: string;
  i18nKeyPlaceholder?: string;
  i18nKeyHelper?: string;
  i18nParamsHelper?: { [key: string]: string };
};

const TextField: React.FC<TextFieldProps> = ({
  v2 = false,
  title,
  subtitle,
  endContent,
  onlyNumbers,
  helperText,
  required,
  disabled,
  showSkeleton = false,
  i18nKeyTitle,
  i18nKeyPlaceholder,
  i18nKeyHelper,
  i18nParamsHelper,
  ...muiProps
}) => {
  const { translate } = useTranslation();
  const theme = useTheme();

  const id = title
    ? `${title.toUpperCase().replace(/ /g, '_')}_TEXT_FIELD`
    : 'TEXT_FIELD';

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      onlyNumbers &&
      (event.key === 'e' ||
        event.key === 'E' ||
        event.key === '+' ||
        event.key === '-')
    ) {
      event.preventDefault();
    }

    muiProps.onKeyDown && muiProps.onKeyDown(event);
  };

  const onPaste = (event: React.ClipboardEvent) => {
    const text = event.clipboardData.getData('TEXT');
    const isValidNumber = /([0-9]+[.]*)+/g.test(text);
    if (onlyNumbers && !isValidNumber) {
      event.preventDefault();
    }
  };

  /************ V2 updates *********************/

  const inputSize = v2 ? 'small' : 'medium';
  const subtitleMargin = v2 ? 1 : '6px';

  /*********************************************/

  const renderHelperText = () => {
    return (
      <InputHelperText i18nKey={i18nKeyHelper} i18nParams={i18nParamsHelper}>
        {helperText}
      </InputHelperText>
    );
  };

  return (
    <SkeletonWrapper isLoading={showSkeleton}>
      <Box data-testid={id} width="100%">
        {title && (
          <InputTitle
            i18nKey={i18nKeyTitle}
            title={title}
            hideMargin={!!subtitle}
            required={required}
            v2={v2}
          />
        )}
        {subtitle && (
          <Typography
            variant="subtitle1"
            marginBottom={subtitleMargin}
            color={theme.palette.text.secondary}
          >
            {subtitle}
          </Typography>
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          <MuiTextField
            fullWidth
            size={inputSize}
            disabled={disabled}
            data-testid="MUI_TEXT_FIELD_INPUT"
            {...muiProps}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            placeholder={
              i18nKeyPlaceholder
                ? translate(i18nKeyPlaceholder, muiProps.placeholder || '')
                : muiProps.placeholder
            }
          />
          {endContent}
        </Stack>
        {!muiProps.error && helperText && renderHelperText()}
        {muiProps.error && helperText && (
          <InputError error={helperText} v2={v2} />
        )}
      </Box>
    </SkeletonWrapper>
  );
};

export default TextField;
